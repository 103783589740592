// todas as informações fornecidas por pagseguro
// https://dev.pagseguro.uol.com.br/reference/split

export const messageErrorPayment = [
  {
    code: 10000,
    message: "Cartão de crédito inválido.",
  },
  {
    code: 10001,
    message: "Número do cartão de crédito com comprimento inválido.",
  },
  {
    code: 10002,
    message: "Formato de data inválido.",
  },
  {
    code: 10003,
    message: "Campo de segurança inválido.",
  },
  {
    code: 10004,
    message: "CVV é obrigatório.",
  },
  {
    code: 10006,
    message: "Campo de segurança com comprimento inválido.",
  },
  {
    code: 53004,
    message: "Itens quantidade inválida.",
  },
  {
    code: 53005,
    message: "A moeda é obrigatória.",
  },
  {
    code: 53006,
    message: "Valor inválido de moeda: {0}",
  },
  {
    code: 53007,
    message: "Comprimento inválido de referência: {0}",
  },
  {
    code: 53008,
    message: "Comprimento inválido do URL de notificação: {0}",
  },
  {
    code: 53009,
    message: "Valor inválido de notificação de URL: {0}",
  },
  {
    code: 53010,
    message: "O e-mail é obrigatório.",
  },
  {
    code: 53011,
    message: "Comprimento inválido do e-mail: {0}",
  },
  {
    code: 53012,
    message: "Valor inválido do e-mail: {0}",
  },
  {
    code: 53013,
    message: "O nome do remetente é obrigatório.",
  },
  {
    code: 53014,
    message: "Comprimento inválido do nome do remetente: {0}",
  },
  {
    code: 53015,
    message: "Valor inválido do nome do remetente: {0}",
  },
  {
    code: 53017,
    message: "CPF inválido: {0}",
  },
  {
    code: 53018,
    message: "O código de área do remetente é obrigatório.",
  },
  {
    code: 53019,
    message: "Valor inválido do código de área do remetente: {0}",
  },
  {
    code: 53020,
    message: "O Telefone é obrigatório.",
  },
  {
    code: 53021,
    message: "Valor inválido do telefone: {0}",
  },
  {
    code: 53022,
    message: "O código postal do endereço de envio é obrigatório.",
  },
  {
    code: 53023,
    message: "Valor inválido do código postal do endereço de entrega: {0}",
  },
  {
    code: 53024,
    message: "A rua do endereço de entrega é obrigatória.",
  },
  {
    code: 53025,
    message: "Comprimento inválido da rua do endereço de entrega: {0}",
  },
  {
    code: 53026,
    message: "O número do endereço de entrega é obrigatório.",
  },
  {
    code: 53027,
    message: "Número do endereço de entrega comprimento inválido: {0}",
  },
  {
    code: 53028,
    message: "Comprimento inválido do complemento do endereço de entrega: {0}",
  },
  {
    code: 53029,
    message: "O distrito do endereço de envio é obrigatório.",
  },
  {
    code: 53030,
    message: "Comprimento inválido do distrito do endereço de entrega: {0}",
  },
  {
    code: 53031,
    message: "A cidade do endereço de entrega é obrigatória.",
  },
  {
    code: 53032,
    message: "Endereço de entrega cidade inválido comprimento: {0}",
  },
  {
    code: 53033,
    message: "O estado do endereço de entrega é obrigatório.",
  },
  {
    code: 53034,
    message: "Valor inválido do estado do endereço de entrega: {0}",
  },
  {
    code: 53035,
    message: "O país do endereço de entrega é obrigatório.",
  },
  {
    code: 53036,
    message: "Comprimento inválido do país do endereço de entrega: {0}",
  },
  {
    code: 53037,
    message: "O token do cartão de crédito é obrigatório.",
  },
  {
    code: 53038,
    message: "Quantidade da parcela é necessária.",
  },
  {
    code: 53039,
    message: "Valor inválido da quantidade da parcela: {0}",
  },
  {
    code: 53040,
    message: "O valor da parcela é obrigatório.",
  },
  {
    code: 53041,
    message: "Valor da parcela valor inválido: {0}",
  },
  {
    code: 53042,
    message: "O nome do titular do cartão de crédito é obrigatório.",
  },
  {
    code: 53043,
    message: "Comprimento inválido do nome do titular do cartão de crédito: {0}",
  },
  {
    code: 53044,
    message: "Valor inválido do nome do titular do cartão de crédito: {0}",
  },
  {
    code: 53045,
    message: "O titular do cartão de crédito cpf é obrigatório.",
  },
  {
    code: 53046,
    message: "Valor inválido do cpf do titular do cartão de crédito: {0}",
  },
  {
    code: 53047,
    message:"A data de nascimento do titular do cartão de crédito é obrigatória.",
  },
  {
    code: 53048,
    message: "Valor inválido da data de nascimento do titular do cartão de crédito: {0}",
  },
  {
    code: 53049,
    message: "O código de área do titular do cartão de crédito é obrigatório.",
  },
  {
    code: 53050,
    message: "Valor inválido do código de área do titular do cartão de crédito: {0}",
  },
  {
    code: 53051,
    message: "É necessário o telefone do titular do cartão de crédito.",
  },
  {
    code: 53052,
    message: "Valor inválido do telefone do titular do cartão de crédito: {0}",
  },
  {
    code: 53053,
    message: "O código postal do endereço de cobrança é obrigatório.",
  },
  {
    code: 53054,
    message: "Valor inválido do código postal do endereço de cobrança: {0}",
  },
  {
    code: 53055,
    message: "A rua do endereço de cobrança é obrigatória.",
  },
  {
    code: 53056,
    message: "Comprimento inválido da rua do endereço de cobrança: {0}",
  },
  {
    code: 53057,
    message: "O número do endereço de cobrança é obrigatório.",
  },
  {
    code: 53058,
    message: "Comprimento inválido do número do endereço de cobrança: {0}",
  },
  {
    code: 53059,
    message: "Comprimento inválido do complemento do endereço de cobrança: {0}",
  },
  {
    code: 53060,
    message: "O distrito do endereço de cobrança é obrigatório.",
  },
  {
    code: 53061,
    message: "Comprimento inválido do distrito do endereço de cobrança: {0}",
  },
  {
    code: 53062,
    message: "A cidade do endereço de cobrança é obrigatória.",
  },
  {
    code: 53063,
    message: "Comprimento inválido da cidade do endereço de cobrança: {0}",
  },
  {
    code: 53064,
    message: "O estado do endereço de cobrança é obrigatório.",
  },
  {
    code: 53065,
    message: "Valor inválido do estado do endereço de cobrança: {0}",
  },
  {
    code: 53066,
    message: "O país do endereço de cobrança é obrigatório.",
  },
  {
    code: 53067,
    message: "Comprimento inválido do país do endereço de faturamento: {0}",
  },
  {
    code: 53068,
    message: "Comprimento inválido do e-mail do destinatário: {0}",
  },
  {
    code: 53069,
    message: "Valor inválido do e-mail do destinatário: {0}",
  },
  {
    code: 53070,
    message: "O id do item é obrigatório.",
  },
  {
    code: 53071,
    message: "Comprimento inválido do id do item: {0}",
  },
  {
    code: 53072,
    message: "A descrição do item é obrigatória.",
  },
  {
    code: 53073,
    message: "Comprimento inválido da descrição do item: {0}",
  },
  {
    code: 53074,
    message: "A quantidade do item é necessária.",
  },
  {
    code: 53075,
    message: "Quantidade do item fora do intervalo: {0}",
  },
  {
    code: 53076,
    message: "Valor inválido da quantidade do item: {0}",
  },
  {
    code: 53077,
    message: "O valor do item é obrigatório.",
  },
  {
    code: 53078,
    message:"Padrão inválido de valor do item: {0}. Deve se ajustar ao padrão:  d +.  D {2}",
  },
  {
    code: 53079,
    message: "Valor do item fora do intervalo: {0}",
  },
  {
    code: 53081,
    message: "O remetente está relacionado ao receptor.",
  },
  {
    code: 53084,
    message:"Destinatário inválido: {0}, verifique o status da conta do destinatário e se é uma conta do vendedor.",
  },
  {
    code: 53085,
    message: "Método de pagamento indisponível.",
  },
  {
    code: 53086,
    message: "Valor total do carrinho fora do permitido R$ : {0}",
  },
  {
    code: 53087,
    message: "Dados de cartão de crédito inválidos.",
  },
  {
    code: 53091,
    message: "Hash do remetente inválido.",
  },
  {
    code: 53092,
    message: "A marca de cartão de crédito não é aceita.",
  },
  {
    code: 53095,
    message: "Padrão de tipo de envio inválido: {0}",
  },
  {
    code: 53096,
    message: "Padrão inválido de custo de frete: {0}",
  },
  {
    code: 53097,
    message: "Custo de envio fora do intervalo: {0}",
  },
  {
    code: 53098,
    message: "O valor total do carrinho é negativo: {0}",
  },
  {
    code: 53099,
    message: "Valor extra padrão inválido: {0}. Deve se ajustar ao padrão: -?  D +.  D {2}",
  },
  {
    code: 53101,
    message: "Valor inválido do modo de pagamento, os valores válidos são padrão e gateway.",
  },
  {
    code: 53102,
    message: "Método de pagamento valor inválido, os valores válidos são cartão de credito, boleto e eft.",
  },
  {
    code: 53104,
    message: "O custo de envio foi fornecido, o endereço de envio deve ser completo.",
  },
  {
    code: 53105,
    message: "As informações do remetente foram fornecidas, o e-mail também deve ser fornecido.",
  },
  {
    code: 53106,
    message: "O titular do cartão de crédito está incompleto.",
  },
  {
    code: 53109,
    message: "As informações do endereço de entrega foram fornecidas, o e-mail do remetente também deve ser fornecido.",
  },
  {
    code: 53110,
    message: "Banco de eft é necessário",
  },
  {
    code: 53111,
    message: "Banco eft não é aceito.",
  },
  {
    code: 53115,
    message: "Data de nascimento inválido: {0}",
  },
  {
    code: 53117,
    message: "CNPJ inválido: {0}",
  },
  {
    code: 53122,
    message: "Domínio inválido do e-mail do remetente: {0}. Você deve usar um email @ sandbox.pagseguro.com.br",
  },
  {
    code: 53140,
    message: "Quantidade da parcela fora do intervalo: {0}. O valor deve ser maior que zero.",
  },
  {
    code: 53141,
    message: "Remetente está bloqueado.",
  },
  {
    code: 53142,
    message: "Token de cartão de crédito inválido.",
  }
];