/**
 * @function ObjectToQueryString
 * @author Samuel de Souza Silva
 */

 export default (obj) => {

  let keys = Object.keys(obj);
  let query = "";

  for(let i = 0; i < keys.length; i++)
  {
      if(i > 0) query += "&";

      let value = obj[keys[i]];
      if(value && typeof value === 'object' && value.isArray )
      {
          let newValue = "";
          for(let j = 0; j < value.length; j++)
          {
              if(j > 0) newValue += ",";
              newValue += value[j];
          }

          value = newValue;
      }

      query += keys[i]+ "="+ value;
  }

  return query;
}