import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import RenderPage from "./components/common/renderPage/renderPages";

function App() {
  return (
    <div className="App">
      <Helmet>
        {process.env.REACT_APP_PAGSEGURO_IMPORT_IS_BETA === "true" ? (
          <script src="https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js"></script>
        ) : (
          <script src="https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js"></script>
        )}
      </Helmet>
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/checkout/:id">
            <RenderPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
