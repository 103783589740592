export const addressStateList = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amap\u00e1",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Cear\u00e1",
  DF: "Distrito Federal",
  ES: "Esp\u00edrito Santo",
  GO: "Goi\u00e1s",
  MA: "Maranh\u00e3o",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PR: "Paran\u00e1",
  PB: "Para\u00edba",
  PA: "Par\u00e1",
  PE: "Pernambuco",
  PI: "Piau\u00ed",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RJ: "Rio de Janeiro",
  RO: "Rond\u00f4nia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SE: "Sergipe",
  SP: "S\u00e3o Paulo",
  TO: "Tocantins",
};
