import React from "react";
import "@lottiefiles/lottie-player";
import { Icon } from "@polichat/flamboyant";
import "./styles.css";

function Animation(props) {
  let style = {
    width: 40,
    height: 40,
  };

  if (props.style) {
    style = props.style;
  }
  let src = "https://assets9.lottiefiles.com/packages/";
  if (props.icon) {
    switch (props.icon) {
      case "microphone":
        src += "lf20_Yxc4wU.json";
        break;
      case "notReadMessage":
        src = "https://assets1.lottiefiles.com/packages/lf20_oryX2K.json";
        break;
      case "loading":
        src += "lf20_VeLVYP.json";
        break;
      case "ack-loading":
        src += "lf20_a8b7zL.json";
        break;
      case "chat-loading":
        src += "lf20_skYQqX.json";
        break;
      case "omni-channel":
        src += "lf20_KKZ0Cd.json";
        break;
      case "omni-channel2":
        return (
          <div className="gallery autoplay items-3 startChatContainer">
            <div id="whatsapp" className="control-operator"></div>
            <div id="messenger" className="control-operator"></div>
            <div id="webchat" className="control-operator"></div>

            <figure className="item itemsStartChat">
              <h4>
                <div className="circulo">
                  <Icon icon={"poli-icon pi-whatsapp-fill"} size={250} />
                </div>
                Vários números em um só lugar.
              </h4>
            </figure>

            <figure className="item itemsStartChat">
              <h4>
                <div className="circulo">
                  <Icon icon={"poli-icon pi-messenger-fill"} size={250} />
                </div>
                Seu messenger em um só lugar.
              </h4>
            </figure>

            <figure className="item itemsStartChat">
              <h4>
                <div className="circulo">
                  <Icon icon={"poli-icon pi-webchat-fill"} size={250} />
                </div>
                WebChat exclusivo para seu site.
              </h4>
            </figure>

            <div className="controls">
              <a href="#whatsapp" className="control-button">
                .
              </a>
              <a href="#messenger" className="control-button">
                .
              </a>
              <a href="#webchat" className="control-button">
                .
              </a>
            </div>
          </div>
        );
      default:
        break;
    }
  }
  if (props.size) {
    if (typeof props.size === "number") {
      style.width = props.size;
      style.height = props.size;
    } else {
      switch (props.size) {
        case "littleTiny":
          style.width = 23;
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = 23;
          break;
        case "tiny":
          style.width = 40;
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = 40;
          break;

        case "small":
          style.width = 150;
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = 150;
          break;

        case "large":
          style.width = 250;
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = 250;
          break;
        case "full":
          style.width = "100%";
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = "100%";
          break;
        default:
          style.width = 40;
          style.textAlign = "center";
          style.margin = "0 auto";
          style.height = 40;
          break;
      }
    }
  }

  let extProps = {
    speed: props.speed || "1",
    background: props.background || "transparent",
    loop: typeof props.loop === "undefined" ? true : props.loop,
    autoplay: typeof props.autoplay === "undefined" ? true : props.autoplay,
  };

  return <lottie-player {...extProps} src={src} style={style}></lottie-player>;
}

export default Animation;
