import React,{useEffect, useState, useCallback} from "react";

// External Libraries
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  FormFeedback,
  Icon,
  UncontrolledTooltip
} from "@polichat/flamboyant";
import {version} from "../../../package.json"

// Components

import SubmitButton from "../../components/common/form/SubmitButton";
import Loading from "../../components/common/loading"

// Utils
import { creditCardMask } from "../../utils/creditCardMask";
import { onlyNumbersFilter } from "../../utils/onlyNumbersFilter";
import { monthYearMask } from "../../utils/monthYearMask";
import { addressStateList } from "../../lib/StateBrazil";
// Assets 
import imageBackground from "../../assets/images/checkout-image.svg"
import logoPoli from "../../assets/images/logo_poli.svg";
// Services - APIs
import { getAddress } from "../../services/cepSearch/correios";
import {
  getCheckoutData,
  changeOrderStatus,
  submitOrderAddress,
} from "../../services/payment/paymentConfig";

function MercadoPagoCheckout () {

    // Status de erro
  const STATUS_LOADING = 0;
  const STATUS_LOADED = 1;
  const STATUS_SERVER_OFFLINE = -1;
  const STATUS_GATEWAY_OFFLINE = -2;
  const STATUS_ORDER_PROCESSED = -3;
  const FORM_STEP_PERSONAL_DATA = 1;
  const FORM_STEP_PERSONAL_DATA2 = 1;

    // Status da aplicação
    const [status, setStatus] = useState(STATUS_LOADING);
    const [orderData, setOrderData] = useState(null);
     // em qual etapa do formulário o usuário está?
    const [formStep, setFormStep] = useState(FORM_STEP_PERSONAL_DATA); 
    const [formStep2, setFormStep2] = useState(FORM_STEP_PERSONAL_DATA); 

    const [isLoading, setIsLoading] = useState(true)
    const [customerPicture, setCustomerPicture] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [nextStep, setNextStep] = useState(false)
    const [nextStep2, setNextStep2 ] = useState(false)
    const [preferenceId, setPreferenceID] = useState('');
    const [publicKey,setPublicKey] = useState('');

 
    //armazena os dados que serão enviados ao gateway do checkout pro
    const [state, setState] = useState({
        
        paymentMode: "default",
        paymentMethod: "",
        currency_id: "BRL",
        extraAmount: (0.0).toFixed(2), // necessário pois a API só aceita duas casas decimais
    
        senderName: "",
        senderDocument: "",
        senderAreaCode: "",
        senderPhone: "",
        senderEmail: "",
   
    
        /**
         * Dados do endereço de entrega
         */
        shippingAddressRequired: true, // obrigar endereço de entrega?
        shippingAddressStreet: "",
        shippingAddressNumber: "",
        shippingAddressComplement: "",
        shippingAddressDistrict: "",
        shippingAddressPostalCode: "",
        shippingAddressCity: "",
        shippingAddressState: "",
        shippingAddressCountry: "BRA",
        shippingType: "1",
        shippingCost: "",
    
        // dados que devem ser removidos antes de submeter
        creditCardNumber: "",
        creditCardCvv: "",
        creditCardDate: "",
        creditCardBrand: "",
        creditCardToken: "",
        creditCardCvvSize: 3,
    
        installmentQuantity: "",
        installmentValue: "",
        maxInstallmentNoInterest: 3,
        noInterestInstallmentQuantity: 3,
    
        creditCardHolderName: "",
        creditCardHolderDocument: "",
        creditCardHolderBirthDate: "",
        creditCardHolderAreaCode: "",
        creditCardHolderPhone: "",
    
        /**
         * Dados do endereço de cobrança do CC
         */
        billingAddressStreet: "",
        billingAddressNumber: "",
        billingAddressComplement: "",
        billingAddressDistrict: "",
        billingAddressPostalCode: "",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressCountry: "BRA",
      });
      
    const tkn = useParams().id;
    const handleKeyDownChanges = (evt) => {
        evt = evt && evt.nativeEvent ? evt.nativeEvent : window.event;
    
        let name = evt.target.name;
    
        if (
          name === "creditCardCvv" ||
          name === "creditCardNumber" ||
          name === "shippingAddressNumber" ||
          name === "senderPhone" ||
          name === "senderAreaCode"
        ) {
          return onlyNumbersFilter(evt);
        }
    
        if (name === "creditCardDate") {
          return monthYearMask(evt);
        }
      };
      const handleChangeInputs = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
    
        if (name === "creditCardHolderName") {
          value = value.toUpperCase();
        }
    
        if (name === "senderDocument" || name === "creditCardHolderDocument") {
          // value = cpfMask(value);
          value = value.replace(/\D/g, "");
        }
    
        if (name === "creditCardNumber") {
          value = creditCardMask(value);
        }
    
        //handle change address inputs
        //change shipping and billing
        if (name === "shippingAddressStreet") {
          setState({
            ...state,
            [evt.target.name]: value,
            billingAddressStreet: value,
          });
        } else if (name === "shippingAddressDistrict") {
          setState({
            ...state,
            [evt.target.name]: value,
            billingAddressDistrict: value,
          });
        } else if (name === "shippingAddressNumber") {
          setState({
            ...state,
            [evt.target.name]: value,
            billingAddressNumber: value,
          });
        } else if (name === "shippingAddressComplement") {
          setState({
            ...state,
            [evt.target.name]: value,
            billingAddressComplement: value,
          });
        } else {
          setState({
            ...state,
            [evt.target.name]: value,
          });
        }
      };

    const handleBlurInputs = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        if (name === "senderName") {
          let error = false;
          if (!value.length) {
            error = true;
          } else {
            let split = value.split(" ");
            if (split.length < 2) {
              error = true;
            } else {
              if (!split[1].length) {
                error = true;
              } else {
                error = false;
              }
            }
          }
          setState({
            ...state,
            senderNameError: error,
            senderName: value,
          });
        }
        if (name === "senderDocument") {
          let error = false;
          if (value.length < 11) {
            error = true;
          } else {
            error = false;
          }

          setState({
            ...state,
            senderDocumentError: error,
            senderDocument: value,
          });
        }

        if (name === "senderEmail") {
          const validateEmail = (email) => {
            const re =
              /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          };
    
          let error = false;
    
          if (!value.length) {
            error = true;
          } else {
            if (!validateEmail(value)) {
              error = true;
            } else {
              error = false;
            }
          }

          setState({
            ...state,
            senderEmailError: error,
            senderEmail: value,
          });
        }
    
        if (name === "senderAreaCode") {
          let error = false;
    
          if (value.length !== 2) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            senderAreaCodeError: error,
            senderAreaCode: value,
          });
        }
    
        if (name === "senderPhone") {
          let error = false;
    
          if (value.length < 7 || value.length > 9) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            senderPhoneError: error,
            senderPhone: value,
          });
         
        }
    
        if (
          name === "shippingAddressPostalCode" ||
          name === "billingAddressPostalCode"
        ) {
          if (value.length) {
            if (value.length !== 8) {
              if (name === "shippingAddressPostalCode") {
                setState({
                  ...state,
                  shippingAddressPostalCodeError: true,
                  shippingAddressPostalCode: value,
                });
              } else {
                setState({
                  ...state,
                  billingAddressPostalCodeError: true,
                  billinggAddressPostalCode: value,
                });
              }
            } else {
              value = value.replaceAll(" ", "");
    
              getAddress(value)
                .then(function (result) {
                  
                  if (result.data) {
                   
                    if (result.data.erro) {
                      if (name === "shippingAddressPostalCode") {
                        setState({
                          ...state,
                          shippingAddressPostalCodeError: true,
                          shippingAddressPostalCode: value,
                        });
                      } else {
                        setState({
                          ...state,
                          billingAddressPostalCodeError: true,
                          billingAddressPostalCode: value,
                        });
                      }
                    } else {
                      let data = result.data;
                       
                      if (name === "shippingAddressPostalCode") {
                        setState({
                          ...state,
                          shippingAddressDistrict: data.bairro,
                          shippingAddressStreet: data.logradouro,
                          shippingAddressComplement: data.complemento,
                          shippingAddressState: data.uf,
                          shippingAddressCity: data.localidade,
                          shippingAddressPostalCodeError: false,
                          shippingAddressPostalCode: value,
                        });
                      } else {
                        setState({
                          ...state,
                          billingAddressDistrict: data.bairro,
                          billingAddressStreet: data.logradouro,
                          billingAddressComplement: data.complemento,
                          billingAddressState: data.uf,
                          billingAddressCity: data.localidade,
                          billingAddressPostalCodeError: false,
                          billingAddressPostalCode: value,
                        });
                      }
                    }
                  }
                })
                .catch(function (error) {
                  if (name === "shippingAddressPostalCode") {
                      console.error("error")
                    setState({
                      ...state,
                      shippingAddressPostalCodeError: false,
                      shippingAddressPostalCode: value,
                    });
                  } else {
                    console.error("error")
                    setState({
                      ...state,
                      billingAddressPostalCodeError: false,
                      billingAddressPostalCode: value,
                    });
                  }
                });
            }
          } else {
            if (name === "shippingAddressPostalCode") {
              setState({
                ...state,
                shippingAddressPostalCodeError: true,
              });
            } else {
              setState({
                ...state,
                billingAddressPostalCodeError: true,
              });
            }
          }
        }
    
        if (name === "shippingAddressStreet") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            shippingAddressStreetError: error,
            shippingAddressStreet: value,
          });
        }
    
        if (name === "billingAddressStreet") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            billingAddressStreetError: error,
            billingAddressStreet: value,
          });
        }
    
        if (name === "shippingAddressDistrict") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            shippingAddressDistrictError: error,
            shippingAddressDistrict: value,
          });
        }
    
        if (name === "billingAddressDistrict") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            billingAddressDistrictError: error,
            billingAddressDistrict: value,
          });
        }
    
        if (name === "shippingAddressNumber") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            shippingAddressNumberError: error,
            shippingAddressNumber: value,
          });
        }
    
        if (name === "billingAddressNumber") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            billingAddressNumberError: error,
            billingAddressNumber: value,
          });
        }
    
        if (name === "shippingAddressComplement") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            shippingAddressComplementError: error,
            shippingAddressComplement: value,
          });
        }
    
        if (name === "billingAddressComplement") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            billingAddressComplementError: error,
            billingAddressComplement: value,
          });
        }
    
        if (name === "shippingAddressCity") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            shippingAddressCityError: error,
            shippingAddressCity: value,
          });
        }
    
        if (name === "billingAddressCity") {
          let error = false;
    
          if (!value) {
            error = true;
          } else {
            error = false;
          }
    
          setState({
            ...state,
            billingAddressCityError: error,
            billingAddressCity: value,
          });
        }
      };
   
      useEffect(() => {


        if (formStep === FORM_STEP_PERSONAL_DATA) {
            if (
              state &&
              state.senderName?.length &&
              state.senderDocument?.length &&
              state.senderEmail?.length &&
              state.senderAreaCode?.length &&
              state.senderPhone?.length &&
              state.billingAddressDistrict?.length > 2 &&
              state.billingAddressStreet?.length > 2 &&
              !state.senderNameError &&
              !state.senderDocumentError &&
              !state.senderEmailError
            ) {
              setNextStep(true);
            } else {
              setNextStep(false);
            }
          }
      }, [state, formStep]);



      useEffect(() => {
        if (formStep2 === FORM_STEP_PERSONAL_DATA) {
            if (
              state &&
              state.senderName?.length &&
              state.senderDocument?.length > 8 &&
              state.senderEmail?.length &&
              state.senderAreaCode?.length > 1 &&
              state.senderPhone?.length > 7 &&
              
              !state.senderNameError &&
              !state.senderDocumentError &&
              !state.senderEmailError
            ) {
              setNextStep2(true);
            } else {
              setNextStep2(false);
            }
          
          }
      }, [state, formStep2]);
    
    const getSessionData = useCallback(() => {
        
    
        
        // Em caso de sucesso
        const getSessionDataSuccess = (data) => {
          if (data) {
            setIsLoading(true)
            if (data.preference_id && data.public_key) {
                setOrderData(data);
                setCustomerPicture(data.customer?.picture);
                setCustomerName(data.customer?.customer_name);
                setPublicKey(data.public_key);
                setPreferenceID(data.preference_id);
                setState({
                  ...state
                });
                
              } else {
                setStatus(STATUS_GATEWAY_OFFLINE);
              }
            }  else {
            setStatus(STATUS_SERVER_OFFLINE);
          }
        
          setIsLoading(false)
        }

     
        if (status !== STATUS_SERVER_OFFLINE) {
          if (tkn) {
            let data = {
              tkn,
            };
    
            getCheckoutData(data)
              .then(function (result) {
                getSessionDataSuccess(result.data);
              })
              .catch(function (err) {
                console.error(err);
              
              });
          } else {
            setStatus(STATUS_SERVER_OFFLINE);
          }
        }
      }, [
        
        tkn,
        STATUS_SERVER_OFFLINE,
        STATUS_GATEWAY_OFFLINE,
        state,status,
        
      ]);

      useEffect(() => {
        if (!orderData) {
          getSessionData();
        }
      }, [getSessionData, orderData]);
      useEffect(() => {
        // altera o status do pedido ao entrar
        const changeOrderStatusToClicked = () => {
          let params = {
            tkn: tkn,
          };
    
          changeOrderStatus(params)
            .then(function (success) {})
            .catch(function (error) {});
        };

        changeOrderStatusToClicked();
      }, []);
     
      useEffect(() => { 

        // Adiciona o endereço ao state
        const setAddress = () => {
          if (orderData.order && !state.addressLoaded ) {
            let address = Object.assign({}, orderData.order.address);
            if (
              address === null ||
              !address.state ||
              !address.country ||
              !address.cep ||
              !address.city
            ) {
              setState({
                ...state,
                shippingAddressRequired: false,
                addressLoaded: true,
              });
            } else {
              setState({
                ...state,
                addressLoaded: true,
                shippingAddressRequired: true,
                shippingAddressStreet:
                  (address.address ? address.address : "") + "",
                shippingAddressNumber: (address.number ? address.number : "") + "",
                shippingAddressComplement:
                  (address.complement ? address.complement : "") + "",
                shippingAddressDistrict:
                  (address.district ? address.district : "") + "",
                shippingAddressPostalCode: address.cep + "",
                shippingAddressCity: address.city + "",
                shippingAddressState: address.state + "",
                shippingAddressCountry: address.country + "",
                billingAddressStreet: (address.address ? address.address : "") + "",
                billingAddressNumber: (address.number ? address.number : "") + "",
                billingAddressComplement:
                  (address.complement ? address.complement : "") + "",
                billingAddressDistrict:
                  (address.district ? address.district : "") + "",
                billingAddressPostalCode: address.cep + "",
                billingAddressCity: address.city + "",
                billingAddressState: address.state + "",
                billingAddressCountry: address.country + "",
              });
            }
          }
         
        };
    
      
        if (status === STATUS_LOADED) {
          setAddress();
        }
      }, [status, orderData, state]);

      useEffect(() => {
        if (orderData && orderData.contact && !state.contactLoaded) {
          setState({
            ...state,
            contactLoaded: true,
            senderName: orderData.contact.name ? orderData.contact.name : "",
            senderDocument: orderData.contact.cpf ? orderData.contact.cpf : "",
            senderAreaCode: orderData.contact.phone
              ? orderData.contact.phone.substr(2, 2)
              : "",
            senderPhone: orderData.contact.phone
              ? orderData.contact.phone.substr(4, 8)
              : "",
            senderEmail: orderData.contact.email ? orderData.contact.email : "",
          });
        }
      }, [orderData, state]);
   
      useEffect(() => {
        if (orderData) {
          if (orderData.order && orderData.order.processed === true) {
            setStatus(STATUS_ORDER_PROCESSED);
          } else {
            if (
                
              tkn &&
              orderData     
              
            ) {
              setStatus(STATUS_LOADED);
            }
          }
        }
       
      }, [
       
        tkn,       
        STATUS_ORDER_PROCESSED,
        orderData,
      ]);
       const handleSubmitContactChangeShippingAddress = () => {
    //Order has shipping address
    if (orderData && orderData.order && orderData.order.address) {
      let _orderAddress = orderData.order.address;
      let _stateAddress = {
        address: state.shippingAddressStreet,
        number: state.shippingAddressNumber,
        complement: state.shippingAddressComplement,
        district: state.shippingAddressDistrict,
        cep: state.shippingAddressPostalCode,
        city: state.shippingAddressCity,
        state: state.shippingAddressState,
        country: state.shippingAddressCountry,
      };
      // orderData.address and state.shippingAddres[values] hare diffents
      if (
        _orderAddress.address !== _stateAddress.address ||
        _orderAddress.complement !== _stateAddress.complement ||
        _orderAddress.district !== _stateAddress.district ||
        _orderAddress.number !== _stateAddress.number
      ) {
        //send request from shopping backend
        submitOrderAddress({
          address: _stateAddress,
          orderId: orderData?.order?.id,
          token: tkn,
        })
          .then(function (success) {})
          .catch(function (error) {});
      }
    }
  };


  function addCheckout() {
    const mp = new window.MercadoPago(publicKey, {
      locale: 'pt-BR'
    });
  
    mp.checkout({ 
      preference: {
          id: preferenceId,
      },
      render: {
          container: `.mp_checkout`,
          label: 'Pagar', 
      },
  });
  }

  useEffect(() => {
    if (preferenceId) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.setAttribute('data-preference-id', preferenceId);
      script.addEventListener('load', addCheckout);
      document.body.appendChild(script);
    }
  }, [preferenceId]);

      //Busca os dados de endereço do cliente
    const getCustomerCurrentAddress = () =>{
        return (
            <>{orderData?.order?.status <= 3 ? 
    <Container>
            <Row xs="1" sm="1" md="2">
              <Col>
                <h5 style={{ textAlign: "initial" }}>Dados Pessoais</h5>
              </Col>
            </Row>

            <Row xs="1" sm="1" md="1" lg="2">
              <Col className="checkout-form-col">
                <Label>Nome completo: </Label>
                <Input
                  invalid={state.senderNameError === true}
                  type="text"
                  autoFocus={true}
                  placeholder="Nome Completo *"
                  name="senderName"
                  defaultValue={state.senderName}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                  maxLength={50}
                />
                {state.senderNameError && (
                  <>
                    <FormFeedback>
                      O nome do comprador é obrigatório e deve ser composto por
                      dois nomes.
                    </FormFeedback>
                  </>
                )}
              </Col>
              <Col className="checkout-form-col">
                <Label>CPF/CNPJ: </Label>
                <Input
                  invalid={state.senderDocumentError === true}
                  type="text"
                  placeholder="CPF/CNPJ *"
                  name="senderDocument"
                  defaultValue={state.senderDocument}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                  minLength={11}
                  maxLength={14}
                />
                {state.senderDocumentError && (
                  <>
                    <FormFeedback>
                      O CPF/CNPJ do comprador é obrigatório.
                    </FormFeedback>
                  </>
                )}
              </Col>
            </Row>

            <Row xs="1" sm="1" md="1" lg="2">
              <Col className="checkout-form-col">
                <Label>E-mail: </Label>
                <Input
                  invalid={state.senderEmailError === true}
                  type="email"
                  placeholder="E-mail *"
                  name="senderEmail"
                  defaultValue={state.senderEmail}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                />
                {state.senderEmailError && (
                  <FormFeedback>
                    O E-mail do comprador é obrigatório e deve estar no formato
                    de um e-mail válido.
                  </FormFeedback>
                )}
              </Col>

              <Col xs="4" sm="4" md="4" lg="2" className="checkout-form-col">
                <Label className="left">DDD: </Label>
                <Input
                  type="text"
                  maxLength={2}
                  placeholder="DDD *"
                  name="senderAreaCode"
                  defaultValue={state.senderAreaCode}
                  onChange={handleChangeInputs}
                  onKeyDown={handleKeyDownChanges}
                  onBlur={handleBlurInputs}
                  invalid={state.senderAreaCodeError}
                />
                {state.senderAreaCodeError && (
                  <>
                    <FormFeedback>
                      O DDD do comprador é obrigatório e deve ter 2 dígitos.
                    </FormFeedback>
                  </>
                )}
              </Col>

              <Col xs="8" sm="8" md="8" lg="4" className="checkout-form-col">
                <Label>Telefone: </Label>
                <Input
                  type="text"
                  placeholder="Telefone *"
                  name="senderPhone"
                  defaultValue={state.senderPhone}
                  onChange={handleChangeInputs}
                  onKeyDown={handleKeyDownChanges}
                  onBlur={handleBlurInputs}
                  invalid={state.senderPhoneError}
                  maxLength={9}
                  minLength={7}
                />
                {state.senderPhoneError && (
                  <>
                    <FormFeedback>
                      O telefone do comprador é obrigatório e deve ter de 7 a 9
                      dígitos.
                    </FormFeedback>
                  </>
                )}
              </Col>
            </Row>
            {/* <br /> */}
            {orderData && orderData.order && orderData.order.address ? (
              <>
                <Row xs="1" sm="1" md="1" lg="2">
                  <Col>
                    <h5 style={{ textAlign: "initial" }}>Dados da Entrega</h5>
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="1" lg="2">
                  <Col className="checkout-form-col">
                    <Label>CEP: </Label>
                    <Input
                      type="text"
                      placeholder="CEP *"
                      name="shippingAddressPostalCode"
                      defaultValue={state.shippingAddressPostalCode}
                      onChange={handleChangeInputs}
                      onBlur={handleBlurInputs}
                      disabled={true}
                      maxLength={8}
                      invalid={state.shippingAddressPostalCodeError}
                    />

                    {state.shippingAddressPostalCodeError && (
                      <>
                        <FormFeedback>Este CEP é inválido.</FormFeedback>
                      </>
                    )}
                  </Col>
                  <Col className="checkout-form-col">
                    <Label>Endereço: </Label>
                    <Input
                      required
                      type="text"
                      placeholder="Endereço *"
                      defaultValue={state.shippingAddressStreet}
                      onChange={handleChangeInputs}
                      name="shippingAddressStreet"
                      onBlur={handleBlurInputs}
                      invalid={state.shippingAddressStreetError === true}
                    />
                    {state.shippingAddressStreetError && (
                      <>
                        <FormFeedback>
                          O campo endereço é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="1" lg="2">
                  <Col className="checkout-form-col">
                    <Label>Bairro: </Label>
                    <Input
                      type="text"
                      name="shippingAddressDistrict"
                      defaultValue={state.shippingAddressDistrict}
                      onChange={handleChangeInputs}
                      placeholder="Bairro *"
                      onBlur={handleBlurInputs}
                      required
                      invalid={state.shippingAddressDistrictError === true}
                    />
                    {state.shippingAddressDistrictError && (
                      <>
                        <FormFeedback>
                          O campo Bairro é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                  <Col
                    xs="10"
                    sm="6"
                    md="6"
                    lg="4"
                    className="checkout-form-col"
                  >
                    <Label>Número:</Label>
                    <Input
                      type="text"
                      placeholder="Número"
                      name="shippingAddressNumber"
                      defaultValue={state.shippingAddressNumber}
                      onChange={handleChangeInputs}
                      
                      onBlur={handleBlurInputs}
                      maxLength={10}
                    />
                   
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="10"
                    lg="10"
                    className="checkout-form-col"
                  >
                    <Label>Complemento:</Label>
                    <Input
                      type="text"
                      placeholder="Complemento *"
                      name="shippingAddressComplement"
                      defaultValue={state.shippingAddressComplement}
                      onChange={handleChangeInputs}
                      onBlur={handleBlurInputs}
                    />
                   
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="2">
                  <Col className="checkout-form-col">
                    <Label>Estado: </Label>
                    <Input
                      type="select"
                      onChange={handleChangeInputs}
                      name="shippingAddressState"
                      value={state.shippingAddressState}
                      disabled={true}
                    >
                      <option value="">Estado</option>
                      {Object.keys(addressStateList).map((key, index) => {
                        return (
                          <option key={index} value={key}>
                            {addressStateList[key]}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col className="checkout-form-col">
                    <Label>Cidade: </Label>
                    <Input
                      type="text"
                      name="shippingAddressCity"
                      defaultValue={state.shippingAddressCity}
                      onChange={handleChangeInputs}
                      placeholder="Cidade *"
                      onBlur={handleBlurInputs}
                      invalid={state.shippingAddressCityError}
                      maxLength={60}
                      disabled={true}
                    />
                    {state.shippingAddressCityError && (
                      <>
                        <FormFeedback>
                          O campo Cidade é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}

            {/* <br /> */}
            <Row xs="1" sm="1" md="1">
              { orderData?.order.address !== null ?
              
              <Col style={{display :formStep === FORM_STEP_PERSONAL_DATA ? 'block': 'none'}}>
                <SubmitButton
                   id="continue_to_send"
                  disabled={!nextStep}
                  size="lg"
                  color="secondary"
                  text="Continuar"
                  onClick={() => {
                    let newState = Object.assign({}, state);
                    newState.creditCardHolderDocument = newState.senderDocument;
                    newState.creditCardHolderAreaCode = newState.senderAreaCode;
                    newState.creditCardHolderPhone = newState.senderPhone;
                    handleSubmitContactChangeShippingAddress();
                    setState({
                      ...state,
                      ...newState,
                    });
                    setFormStep(4)
                    setFormStep2(4)
                  }}
                />
                {!nextStep ? 
                 <UncontrolledTooltip placement="top" target="continue_to_send">
                 Favor preencher todos os campos obrigatórios
               </UncontrolledTooltip> 
               
               : null}
               
              </Col>
              : 
              <Col style={{display :formStep2 === FORM_STEP_PERSONAL_DATA2 ? 'block': 'none'}}>
                <SubmitButton
                   id="continue_to_send"
                  disabled={!nextStep2}
                  size="lg"
                  color="secondary"
                  text="Continuar"
                  onClick={() => {
                    let newState = Object.assign({}, state);
                    newState.creditCardHolderDocument = newState.senderDocument;
                    newState.creditCardHolderAreaCode = newState.senderAreaCode;
                    newState.creditCardHolderPhone = newState.senderPhone;
                    handleSubmitContactChangeShippingAddress();
                    setState({
                      ...state,
                      ...newState,
                    });
                    setFormStep2(4)
                    setFormStep(4)
                  }}
                />
                {!nextStep2 ? 
                 <UncontrolledTooltip placement="top" target="continue_to_send">
                 Favor preencher todos os campos obrigatórios
               </UncontrolledTooltip> 
               
               : null}
               
              </Col>
              }
              
                          
              
            </Row>
          </Container>

          :orderData?.order?.status === 4 ? 
          
          <Container>

            <h3>Esse pedido está  sendo processado.</h3>
            </Container>
            
          : orderData?.order?.status === 5 ? 
          <Container>

            <h3>O pedido se encontra pago.</h3>
            </Container> :
            orderData?.order?.status === 6 ? 
            <Container>

            <h3>O pedido foi recusado.</h3>
            </Container> :
             orderData?.order?.status === 7 ?

             <Container>

            <h3>O prazo do pedido expirou.</h3>
            </Container> :

orderData?.order?.status === 8 ?
<Container>

<h3>O pedido foi estornado.</h3>
</Container> :
 <Container>

 <h3>O pedido foi cancelado.</h3>
 </Container> 




    }
            </>
        )
      }
    return(
        <>
           {isLoading ?(
        <div className="checkout-loading-container content-grid">
          <Loading />
        </div>
      ) :
         <div className="checkout-container grid-template">
         <div className="content-grid">
 <div className="checkout-main-content">
            <div className="checkout-main-content-header">
              <div className="checkout-main-content-header-image">
                {customerPicture ? (
                 <img
                    alt={customerName ? customerName : "Imagem do cliente"}
                    src={customerPicture}
                    onError={() => setCustomerPicture(null)}
                    height="30px"
                  /> 
                 ) : ( 
                  <div className="checkout-main-content-header-name">
                    <div className="header-polichat-polipay">
                      <img className="poli-logo" src={logoPoli} alt="Poli logo" height={100} width={100}/>
                      <span>+</span>
                    </div>
                    <span className="header-customer-name">{customerName}</span>
                  </div>
                 )} 
              </div>

            </div>
            
              <div className="checkout-main-content-body">
              <div className="checkout-main-content-body-form">
                <div className="checkout-body-header">
                {orderData?.order?.status <= 3 ? 
                    <>
                      <div className="body-header-container">
                        <span className="checkout-body-header-subtitle">
                          Não compartilhamos seus dados financeiros com ninguém.
                        </span>
                        <Icon
                          id="locked-icon"
                          color="var(--primary)"
                          size={22}
                          icon="poli-icon pi-password-fill"
                        />
                      </div>
                      <span className="checkout-body-header-title">
                        Finalize seu pedido
                      </span>
                    </>
                    : null }
                </div>

                <div className="checkout-body-content" style={{display: formStep === 1  || formStep2 === 1 ? 'flex': 'none'}}>{getCustomerCurrentAddress()}
                </div>
                <div className="checkout-body-content" style={{display: formStep === 4  ? 'flex': 'none'}}><span className="mp_checkout" onClick={() => setFormStep(5)}></span>
                </div>
                <div className="checkout-body-content" style={{display: formStep === 5 ? 'flex': 'none'}}><h5>Sua requisição esta sendo enviada.</h5>
                </div>
              </div>

              <div className="checkout-main-content-body-image">
                <img
                  alt="example"
                  src={imageBackground}
                  height="276"
                  width="250"
                />
              </div>
            </div>
            
            </div>
            </div>
            <div className="footer-all footer-grid">
        <a
          href="https://polichat.com.br/"
          target="blank"
          style={{ textDecoration: "none" }}
        >
          <span>Powered by</span>
          <img alt="example" src={logoPoli} height="30" />
          <span>{version}</span>
        </a>
      </div>
            </div>}
            
        </>

    )
}

export default MercadoPagoCheckout
