import React, { useEffect, useState, useCallback } from "react";

// External Libraries
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Icon,
} from "@polichat/flamboyant";
import {version} from "../../../package.json"
// Assets

import imageBackground from "../../assets/images/checkout-image.svg";
import imageSegura from "../../assets/images/logo_seguro.svg";
import logoPoli from "../../assets/images/logo_poli.svg";
// Utils
import { creditCardMask } from "../../utils/creditCardMask";
import { onlyNumbersFilter } from "../../utils/onlyNumbersFilter";
import { monthYearMask } from "../../utils/monthYearMask";
import { convertMonetary } from "../../utils/convertMonetary";
import { addressStateList } from "../../lib/StateBrazil";

// Services - APIs
import { getAddress } from "../../services/cepSearch/correios";
import {
  getCheckoutData,
  submitPayment,
  changeOrderStatus,
  submitOrderAddress,
} from "../../services/payment/paymentConfig";

// Components
import ModalStatus from "../../components/common/modals/ModalStatus";
import Loading from "../../components/common/loading";
import SubmitButton from "../../components/common/form/SubmitButton";

const { messageErrorPayment } = require("../../lib/MessageErrorPayment");

function Checkout() {
  // URL necessária para carregar as imagens das bandeiras de cartão
  const pagSeguroCDNBaseURI = "https://stc.pagseguro.uol.com.br";

  // Status de erro
  const STATUS_LOADING = 0;
  const STATUS_LOADED = 1;
  const STATUS_SERVER_OFFLINE = -1;
  const STATUS_GATEWAY_OFFLINE = -2;
  const STATUS_ORDER_PROCESSED = -3;

  const FORM_STEP_PERSONAL_DATA = 1;
  const FORM_STEP_PAYMENT_METHOD = 2;
  const FORM_STEP_FINISHED_PAYMENT = 3;

  const ONE_TIME_PAYMENT = 0;

  // Status da aplicação
  const [status, setStatus] = useState(STATUS_LOADING);

  // quantas tentativas foram na request?
  const [numberOfFailureRequests, setnumberOfFailureRequests] = useState(0);

  // modal de detalhe está aberto?
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  // em qual etapa do formulário o usuário está?
  const [formStep, setFormStep] = useState(FORM_STEP_PERSONAL_DATA);

  // pode passar para a próxima etapa?
  const [isContinueButtonEnabled, setContinueButtonEnabled] = useState(false);

  // pode processar o pagamento
  const [isFinishButtonEnabled, setFinishButtonEnabled] = useState(false);

  const [
    isCreditCardOwnerShipmentFieldsShow,
    setCreditCardOwnerShipmentFields,
  ] = useState(true);

  const [isCreditCardAddressFieldsShow, setCreditCardAddressFields] =
    useState(false);

  const [isSubmitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);

  // Armazena os dados referentes ao pagamento ( produtos, valor, etc ) e referentes à sessão do gateway
  const [orderData, setOrderData] = useState(null);

  const [totalBill, setTotalBill] = useState(0);

  // Armazena o link do pdf do boleto de pagamento
  // Criamos um state separado para consertar um bug
  // onde o link estava sumindo de dentro do state geral do componente
  const [paymentLink, setPaymentLink] = useState(null);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [installments, setInstallments] = useState([]); // parcelamento
  const [PagSeguroDirectPayment, setPagSeguroDirectPayment] = useState(null);
  const [customerPicture, setCustomerPicture] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [maxInstallmentsNoInterest, setMaxInstallmentsNoInterest] = useState(0);
  const [includeInstallment, setIncludeInstallment] = useState(1);

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setFailureModalOpen] = useState(false);
  const [messageErroPayment, setmessageErroPayment] = useState(
    "Houve um erro ao processar o seu pagamento. Verifique os dados informados novamente."
  );

  // Armazena os dados que serão enviados ao gateway PagSeguro
  const [state, setState] = useState({
    paymentMode: "default",
    paymentMethod: "",
    currency: "BRL",
    extraAmount: (0.0).toFixed(2), // necessário pois a API só aceita duas casas decimais

    senderName: "",
    senderDocument: "",
    senderAreaCode: "",
    senderPhone: "",
    senderEmail: "",
    senderHash: "",

    /**
     * Dados do endereço de entrega
     */
    shippingAddressRequired: true, // obrigar endereço de entrega?
    shippingAddressStreet: "",
    shippingAddressNumber: "",
    shippingAddressComplement: "",
    shippingAddressDistrict: "",
    shippingAddressPostalCode: "",
    shippingAddressCity: "",
    shippingAddressState: "",
    shippingAddressCountry: "BRA",
    shippingType: "1",
    shippingCost: "",

    // dados que devem ser removidos antes de submeter
    creditCardNumber: "",
    creditCardCvv: "",
    creditCardDate: "",
    creditCardBrand: "",
    creditCardToken: "",
    creditCardCvvSize: 3,

    installmentQuantity: "",
    installmentValue: "",
    maxInstallmentNoInterest: 3,
    noInterestInstallmentQuantity: 3,

    creditCardHolderName: "",
    creditCardHolderDocument: "",
    creditCardHolderBirthDate: "",
    creditCardHolderAreaCode: "",
    creditCardHolderPhone: "",

    /**
     * Dados do endereço de cobrança do CC
     */
    billingAddressStreet: "",
    billingAddressNumber: "",
    billingAddressComplement: "",
    billingAddressDistrict: "",
    billingAddressPostalCode: "",
    billingAddressCity: "",
    billingAddressState: "",
    billingAddressCountry: "BRA",
  });

  // id do pedido
  const tkn = useParams().id;

  const getSessionData = useCallback(() => {
    // Inicializa a session do pagseguro
    const setSession = (sessionId) => {
      if (PagSeguroDirectPayment) {
        PagSeguroDirectPayment.setSessionId(sessionId);
      }
    };

    // Gera um hash do pagamento
    const generateHash = () => {
      PagSeguroDirectPayment.onSenderHashReady(function (response) {
        if (response.status === "error") {
          setState({
            ...state,
            senderHash: null,
          });
        }

        let hash = response.senderHash;

        setState({
          ...state,
          senderHash: hash,
        });
      });
    };

    // Busca os tipos de pagamento disponíveis na conta
    const getPaymentMethods = () => {
      let amount = totalBill;

      if (PagSeguroDirectPayment) {
        PagSeguroDirectPayment.getPaymentMethods({
          amount: convertMonetary(amount, false),
          success: function (response) {
            if (response.paymentMethods) {
              // ignorar os métodos de pagamento 'BALANCE' e 'DEPOSIT', POIS NÃO IREMOS TRABALHAR COM ISSO AGORA
              if (response.paymentMethods.BALANCE) {
                delete response.paymentMethods.BALANCE;
              }
              if (response.paymentMethods.ONLINE_DEBIT) {
                delete response.paymentMethods.ONLINE_DEBIT;
              }
              if (response.paymentMethods.DEPOSIT) {
                delete response.paymentMethods.DEPOSIT;
              }
              if (response.paymentMethods.DEBIT_CARD) {
                delete response.paymentMethods.DEBIT_CARD;
              }
              setPaymentMethods(response.paymentMethods);
            }
          },
          error: function (response) {
            console.error(response);
          },
        });
      } else {
        console.error(
          "Não foi possível buscar os métodos de pagamento, pois não existe o objeto do Gateway de pagamento."
        );
      }
    };

    // Em caso de sucesso
    const getSessionDataSuccess = (data) => {
      if (data) {
        if (data.session) {
          if (
            data.session.original &&
            data.session.original.id &&
            PagSeguroDirectPayment
          ) {
            setOrderData(data);

            setSession(data.session.original.id[0]);

            getPaymentMethods();

            generateHash();

            setCustomerPicture(data.customer?.picture);
            setCustomerName(data.customer?.customer_name);
            setMaxInstallmentsNoInterest(
              parseInt(data.customer?.max_installments_no_interest ?? 3)
            );

            setState({
              ...state,
              noInterestInstallmentQuantity: parseInt(
                data.customer?.max_installments_no_interest ?? 3
              ),
            });
          } else {
            setStatus(STATUS_GATEWAY_OFFLINE);
          }
        } else {
          setStatus(STATUS_GATEWAY_OFFLINE);
        }
      } else {
        setStatus(STATUS_SERVER_OFFLINE);
      }
    };
   
    // Em caso de falha
    const getSessionDataFailure = () => {
      setnumberOfFailureRequests(numberOfFailureRequests + 1);
    };

    if (status != STATUS_SERVER_OFFLINE) {
      if (tkn) {
        let data = {
          tkn,
        };

        getCheckoutData(data)
          .then(function (result) {
            getSessionDataSuccess(result.data);
            setIncludeInstallment(result?.data?.customer?.include_installment);
          })
          .catch(function (err) {
            console.error(err);
            getSessionDataFailure(err);
          });
      } else {
        setStatus(STATUS_SERVER_OFFLINE);
      }
    }
  }, [
    numberOfFailureRequests,
    tkn,
    PagSeguroDirectPayment,
    STATUS_SERVER_OFFLINE,
    STATUS_GATEWAY_OFFLINE,
    state,
    totalBill,
  ]);

  useEffect(() => {
    // altera o status do pedido ao entrar
    const changeOrderStatusToClicked = () => {
      let params = {
        tkn: tkn,
      };

      changeOrderStatus(params)
        .then(function (success) {})
        .catch(function (error) {});
    };

    const loadGateway = () => {
      if (window.PagSeguroDirectPayment) {
        setPagSeguroDirectPayment(window.PagSeguroDirectPayment);
      }
    };

    changeOrderStatusToClicked();
    loadGateway();
  }, [tkn]);

  useEffect(() => {
    if (state.paymentLink) finishPayment();
  }, [state.paymentLink]);

  // Verficação de status
  useEffect(() => {
    // Adiciona o endereço ao state
    const setAddress = () => {
      if (orderData.order && !state.addressLoaded) {
        let address = Object.assign({}, orderData.order.address);
 
        if (
          address === null ||
          !address.state ||
          !address.country ||
          // !address.address ||
          // !address.complement ||
          // !address.district ||
          !address.cep ||
          !address.city
        ) {
          setState({
            ...state,
            shippingAddressRequired: false,
            addressLoaded: true,
          });
        } else {
          setState({
            ...state,
            addressLoaded: true,
            shippingAddressRequired: true,
            shippingAddressStreet:
              (address.address ? address.address : "") + "",
            shippingAddressNumber: (address.number ? address.number : "") + "",
            shippingAddressComplement:
              (address.complement ? address.complement : "") + "",
            shippingAddressDistrict:
              (address.district ? address.district : "") + "",
            shippingAddressPostalCode: address.cep + "",
            shippingAddressCity: address.city + "",
            shippingAddressState: address.state + "",
            shippingAddressCountry: address.country + "",

            billingAddressStreet: (address.address ? address.address : "") + "",
            billingAddressNumber: (address.number ? address.number : "") + "",
            billingAddressComplement:
              (address.complement ? address.complement : "") + "",
            billingAddressDistrict:
              (address.district ? address.district : "") + "",
            billingAddressPostalCode: address.cep + "",
            billingAddressCity: address.city + "",
            billingAddressState: address.state + "",
            billingAddressCountry: address.country + "",
          });
        }
      }
    };

    if (status === STATUS_LOADED) {
      setAddress();
    }
  }, [status, orderData, state]);

  useEffect(() => {
    if (orderData && orderData.contact && !state.contactLoaded) {
      setState({
        ...state,
        contactLoaded: true,
        senderName: orderData.contact.name ? orderData.contact.name : "",
        senderDocument: orderData.contact.cpf ? orderData.contact.cpf : "",
        senderAreaCode: orderData.contact.phone
          ? orderData.contact.phone.substr(2, 2)
          : "",
        senderPhone: orderData.contact.phone
          ? orderData.contact.phone.substr(4, 8)
          : "",
        senderEmail: orderData.contact.email ? orderData.contact.email : "",
      });
    }
  }, [orderData, state]);

  // Condição para inicializar a tela
  useEffect(() => {
    if (orderData) {
      if (orderData.order && orderData.order.processed === true) {
    
        setStatus(STATUS_ORDER_PROCESSED);
      
      } else {
        if (
          PagSeguroDirectPayment &&
          tkn &&
          orderData &&
          state.senderHash &&
          state.senderHash.length &&
          paymentMethods
          
        ) {
          setStatus(STATUS_LOADED);
        }
      }
    }
   
  }, [
    PagSeguroDirectPayment,
    tkn,
    state.senderHash,
    paymentMethods,
    STATUS_ORDER_PROCESSED,
    orderData,
  ]);

  // Buscar dados do pedido ao inicializar o pagseguro
  useEffect(() => {
    if (PagSeguroDirectPayment && !orderData) {
      getSessionData();
    }
  }, [PagSeguroDirectPayment, getSessionData, orderData]);

  // Verifica se pode habilitar o botão de continuar
  useEffect(() => {
    if (formStep === FORM_STEP_PERSONAL_DATA) {
      if (
        state &&
        state.senderName?.length &&
        state.senderDocument?.length &&
        state.senderEmail?.length &&
        state.senderAreaCode?.length &&
        state.senderPhone?.length &&
        !state.senderNameError &&
        !state.senderDocumentError &&
        !state.senderEmailError

        // state.shippingAddressPostalCode?.length &&
        // state.shippingAddressStreet?.length &&
        // state.shippingAddressDistrict?.length &&
        // state.shippingAddressStreet?.length &&
        // state.shippingAddressDistrict?.length &&
        // state.shippingAddressNumber?.length &&
        // state.shippingAddressComplement?.length &&
        // state.shippingAddressCity?.length &&
        // state.shippingAddressState !== ""
        // !state.shippingAddressPostalCodeError &&
        // !state.senderAreaCodeError &&
        // !state.shippingAddressPostalCodeError &&
        // !state.shippingAddressStreetError &&
        // !state.shippingAddressNumberError &&
        // !state.shippingAddressComplementError &&
        // !state.shippingAddressCityError &&
        // !state.senderPhoneError
      ) {
        //Verificar se possue address na order
        //para então validar campos de endereço
        //antes de habilitar botão  Continuar
        if (orderData && orderData.order && orderData.order.address) {
          if (
            state.shippingAddressPostalCode?.length &&
            state.shippingAddressStreet?.length &&
            state.shippingAddressDistrict?.length &&
            state.shippingAddressComplement?.length &&
            state.shippingAddressNumber?.length
          ) {
            setContinueButtonEnabled(true);
          } else {
            setContinueButtonEnabled(false);
          }
        } else {
          setContinueButtonEnabled(true);
        }
      } else {
        setContinueButtonEnabled(false);
      }
    } else if (formStep === FORM_STEP_PAYMENT_METHOD) {
      switch (state.paymentMethod) {
        case "creditCard":
          //Mostra opção de marcar dono do cartao somente se o comprador for cpf
          if (
            isCreditCardOwnerShipmentFieldsShow &&
            state.senderDocument.length == 14
          ) {
            toggleCreditCardOwnerShipmentFields();
          }

          if (
            state.paymentMethod !== null &&
            state.creditCardNumber?.length &&
            state.creditCardDate?.length &&
            state.creditCardCvv?.length &&
            state.creditCardHolderName?.length &&
            state.creditCardHolderPhone?.length &&
            state.creditCardHolderAreaCode?.length &&
            state.senderHash?.length &&
            (isCreditCardOwnerShipmentFieldsShow === true ||
              (isCreditCardOwnerShipmentFieldsShow === false &&
                state.creditCardHolderBirthDate?.length &&
                state.creditCardHolderDocument?.length)) &&
            !state.creditCardDateError &&
            state.creditCardCorrect &&
            !state.creditCardHolderDocumentError &&
            !state.creditCardHolderPhoneError &&
            !state.creditCardHolderAreaCodeError &&
            !state.creditCardCvvError &&
            !state.creditCardHolderNameError &&
            !state.creditCardHolderBirthDateError &&
            !state.billingAddressCityError &&
            !state.billingAddressDistrictError &&
            !state.billingAddressComplementError &&
            !state.billingAddressCountryError &&
            !state.billingAddressStateError &&
            !state.billingAddressNumberError &&
            !state.billingAddressPostalCodeError &&
            state.billingAddressPostalCode.length &&
            state.billingAddressState.length &&
            state.billingAddressComplement.length &&
            state.billingAddressDistrict &&
            state.billingAddressCity &&
            state.billingAddressNumber.length
          ) {
            setFinishButtonEnabled(true);
          } else {
            setFinishButtonEnabled(false);
          }
          break;

        case "boleto":
          setFinishButtonEnabled(true);
          break;

        default:
          setFinishButtonEnabled(false);
          break;
      }
    }
  }, [state, isCreditCardOwnerShipmentFieldsShow, formStep]);

  /**
   * Montando Erro do PagSeguro
   */
  const mountErrorPayment = (response) => {
    if (response) {
      // Extrando os erros do objeto do retorno do PagSeguro
      const errorsPagSeguro = JSON.parse(JSON.parse(response)?.data);
      const errorsMsgArray = Array.isArray(errorsPagSeguro?.error)
        ? errorsPagSeguro.error
        : errorsPagSeguro?.error?.code
        ? [errorsPagSeguro.error]
        : null;

      // Montando o componente e o texto de exibição do erro
      if (Array.isArray(errorsMsgArray)) {
        const renderMsg = errorsMsgArray.map((ele, index) => {
          // Buscando mensagem de erro traduzido para pt-br
          let found = messageErrorPayment.find(
            (e) => parseInt(e.code) === parseInt(ele.code)
          );

          // Substituir o value da mensagem para a mensagem traduzida
          if (ele?.message && ele?.message?.split(": ")?.length > 1) {
            const subsValue = ele?.message?.split(": ")[1]?.split(". ")[0];
            if (subsValue && found?.message) {
              found.message = found.message.replace("{0}", subsValue);
            }
          }

          // Se não encontrar a message traduzida, irar exibir a original
          if (!found) found = ele;

          return (
            <li key={index}>
              "{found.message}"{" "}
              <small style={{ color: "red" }}> ({found.code})</small>
              <br></br>
            </li>
          );
        });

        // Inserido o modal o componente do erro montado
        setmessageErroPayment(renderMsg);
      }
    }
  };
  /* End - Montando Erro do PagSeguro */

  // Ao submeter o formulário
  useEffect(() => {
    // adiciona os itens (produtos) aos dados do Pagseguro
    const addItemsToPaymentObject = (order) => {
      let items = [];

      // objeto no formato que o Pagseguro espera
      let objItems = {};

      const isPayment = order.isPayment;

      if (isPayment) {
        objItems.itemId1 = 1;
        objItems.itemDescription1 = order.description
          ? order.description
          : "Cobrança";
        objItems.itemAmount1 = convertMonetary(order.total, false);
        objItems.itemQuantity1 = 1;
        objItems.shippingCost = 0;
      } else {
        let shipping_cost = order.shipping_cost;
        items = order.items;

        if (items) {
          items = Array.from(items);

          items.forEach((product, key) => {
            let indexId = "itemId" + (key + 1);
            let indexDescription = "itemDescription" + (key + 1);
            let indexAmount = "itemAmount" + (key + 1);
            let indexQuantity = "itemQuantity" + (key + 1);

            objItems[indexId] = key + 1;
            objItems[indexDescription] = product.name;
            objItems[indexAmount] = convertMonetary(product.price, false);
            objItems[indexQuantity] = product.quantity;
          });
        }

        if (shipping_cost) {
          objItems.shippingCost = convertMonetary(shipping_cost, false);
        }
      }

      setState({
        ...state,
        ...objItems,
        itemsAddedToPayment: true,
      });
    };

    const boletoCheckoutSubmit = () => {
      if (state.senderHash) {
        let _state = Object.assign({}, state);

        setPaymentProcessing(true);

        // remove alguns atributos desnecessários
        _state = deleteUncessaryStateAttributes(_state);

        // adiciona o token da URL à requisição
        _state.tkn = tkn;

        // submete no pagseguro
        submitPayment(_state)
          .then(function (response) {
            if (
              response.data &&
              response.data.success &&
              response.data.paymentLink &&
              response.data.paymentLink.length > 0
            ) {
              setState({
                ...state,
                paymentLink: response.data.paymentLink,
              });
              setPaymentLink(response.data.paymentLink);
            } else {
              if (response?.data?.message) {
                mountErrorPayment(response.data.message);
              }
              setFailureModalOpen(true);
            }
          })
          .catch(function (err) {
            if (err?.response?.data?.message) {
              mountErrorPayment(err.response.data.message);
            }
            setFailureModalOpen(true);
          })
          .finally(function () {
            setSubmitButtonLoading(false);
            setPaymentProcessing(false);
            setState({
              ...state,
              validated: false,
            });
          });
      }
    };

    // Com posse de todas as informações, submete a cobrança ao pagseguro
    const creditCardCheckoutSubmit = () => {
      if (isSubmitButtonLoading && state.senderHash && state.creditCardToken) {
        setPaymentProcessing(true);

        let _state = Object.assign({}, state);

        _state = createCreditCardHolderData(_state);

        // remove alguns atributos desnecessários
        _state = deleteUncessaryStateAttributes(_state);

        // adiciona o token da URL à requisição
        _state.tkn = tkn;

        submitPayment(_state)
          .then(function (response) {
            if (response.data) {
              if (response.data.success) {
                setSuccessModalOpen(true);
              } else if (response.data.error) {
                if (response?.data?.message) {
                  mountErrorPayment(response.data.message);
                }
                setFailureModalOpen(true);
              } else {
              }
            } else {
              if (response?.data?.message) {
                mountErrorPayment(response.data.message);
              }
              setFailureModalOpen(true);
              //tratar os erros
            }
          })
          .catch(function (err) {
            if (err?.response?.data?.message) {
              mountErrorPayment(err.response.data.message);
            }
            setFailureModalOpen(true);
          })
          .finally(function () {
            setSubmitButtonLoading(false);
            setPaymentProcessing(false);
            setState({
              ...state,
              validated: false,
            });
          });
      } else {
        // console.log("falta informação");
      }
    };

    const createCardToken = () => {
      let _state = Object.assign({}, state);

      let expirationDate = _state.creditCardDate;
      let creditCardNumber = _state.creditCardNumber;
      let creditCardCvv = _state.creditCardCvv;
      let creditCardBrand = _state.creditCardBrand;

      if (!expirationDate.length) {
        return;
      }

      let split = expirationDate.split("/");

      if (split.length < 1) {
        return;
      }

      let month = split[0];

      if (month.length === 1) {
        month = "0" + month;
      }

      let year = split[1];
      if (year.length === 2) {
        year = "20" + year;
      }

      let params = {
        cardNumber: creditCardNumber.replaceAll(" ", ""), // Número do cartão de crédito
        brand: creditCardBrand, // Bandeira do cartão
        cvv: creditCardCvv, // CVV do cartão
        expirationMonth: month, // Mês da expiração do cartão
        expirationYear: year, // Ano da expiração do cartão, é necessário os 4 dígitos.
        success: function (response) {
          if (response && response.card && response.card.token) {
            setState({
              ...state,
              creditCardCorrect: true,
              creditCardToken: response.card.token,
            });
          } else {
            setState({
              ...state,
              creditCardCorrect: false,
            });
          }
        },
        error: function (response) {
          console.error(response);
          setState({
            ...state,
            creditCardCorrect: false,
          });
        },
      };

      PagSeguroDirectPayment.createCardToken(params);
    };

    // validações antes de submeter para o back
    const validate = () => {
      let newState = Object.assign({}, state);

      // converte qualquer valor (string ou numérico) para 2 casas decimais
      const parseTwoDecimals = (value) => {
        if (typeof value !== "number") {
          value = parseFloat(value);
        }
        return value.toFixed(2);
      };

      if (newState.amount) {
        newState.amount = parseTwoDecimals(newState.amount);
      }

      if (newState.shippingCost) {
        newState.shippingCost = parseTwoDecimals(newState.shippingCost);
      }

      if (newState.senderDocument) {
        newState.senderDocument = newState.senderDocument.replace(/[^\d]/g, "");
        if (newState.senderDocument.length === 11)
          newState.senderCPF = newState.senderDocument;
        else if (newState.senderDocument.length === 14)
          newState.senderCNPJ = newState.senderDocument;
      }

      if (newState.extraAmount) {
        newState.extraAmount = parseTwoDecimals(newState.extraAmount);
      }

      switch (newState.paymentMethod) {
        case "creditCard":
          if (newState.installmentValue) {
            newState.installmentValue = parseTwoDecimals(
              newState.installmentValue
            );
          }

          const hasAddress =
            orderData && orderData.order && orderData.order.address;

          if (isCreditCardOwnerShipmentFieldsShow) {
            if (hasAddress) {
              newState.billingAddressStreet =
                newState.shippingAddressStreet.toString();
              newState.billingAddressNumber =
                newState.shippingAddressNumber.toString();
              newState.billingAddressComplement =
                newState.shippingAddressComplement.toString();
              newState.billingAddressDistrict =
                newState.shippingAddressDistrict.toString();
              newState.billingAddressPostalCode =
                newState.shippingAddressPostalCode.toString();
              newState.billingAddressCity =
                newState.shippingAddressCity.toString();
              newState.billingAddressState =
                newState.shippingAddressState.toString();
              newState.billingAddressCountry = "BRA";
            }
          } else {
            if (!state.creditCardHolderDocument.length) {
              newState.creditCardHolderDocumentError = true;
            } else {
              newState.creditCardHolderDocumentError = false;
            }
          }

          if (
            newState.creditCardHolderBirthDate &&
            newState.creditCardHolderBirthDate.length
          ) {
            let split = newState.creditCardHolderBirthDate.split("-");
            if (split.length === 3) {
              let year = split[0];
              let month = split[1];
              let day = split[2];

              let newDate = day + "/" + month + "/" + year;

              newState.creditCardHolderBirthDate = newDate;
              newState.creditCardHolderBirthDateError = false;
            } else if (newState.creditCardHolderBirthDate.includes("-")) {
              newState.creditCardHolderBirthDateError = true;
            } else {
              newState.creditCardHolderBirthDateError = false;
            }
          } else {
            newState.creditCardHolderBirthDateError = true;
          }

          break;

        default:
          break;
      }

      setState({
        ...state,
        ...newState,
        validated: true,
      });
    };

    if (isSubmitButtonLoading) {
      if (orderData && orderData.order && !state.itemsAddedToPayment) {
        addItemsToPaymentObject(orderData.order);
      }

      if (
        orderData &&
        orderData.order &&
        state.itemsAddedToPayment &&
        state.paymentMethod &&
        !isPaymentProcessing
      ) {
        if (!state.validated) validate();

        switch (state.paymentMethod) {
          case "creditCard":
            if (state.validated && !state.creditCardToken) {
              createCardToken();
            } else if (state.validated && state.creditCardToken) {
              creditCardCheckoutSubmit();
            }
            break;

          case "boleto":
            if (state.validated && !state.paymentLink) {
              boletoCheckoutSubmit();
            }
            break;

          default:
            break;
        }
      }
    }
  }, [
    state,
    isSubmitButtonLoading,
    orderData,
    PagSeguroDirectPayment,
    tkn,
    isCreditCardOwnerShipmentFieldsShow,
  ]);

  // Escuta falhas ao buscar sessão
  useEffect(() => {
    if (numberOfFailureRequests < 3 && numberOfFailureRequests >= 1) {
      getSessionData();
    } else if (numberOfFailureRequests >= 3) {
      setStatus(STATUS_SERVER_OFFLINE);
    }
  }, [numberOfFailureRequests, STATUS_SERVER_OFFLINE, getSessionData]);

  // Quando o orderData for buscado
  useEffect(() => {
    // Calcula o total
    const calculateTotal = () => {
      let total = 0;

      const isPayment =
        orderData && orderData.order && orderData.order.isPayment;

      if (isPayment) {
        total = orderData.order.total;
      } else {
        if (orderData && orderData.order && orderData.order.items) {
          orderData.order.items.forEach((product) => {
            total += parseFloat(product.price) * parseInt(product.quantity);
          });
        }

        if (orderData?.order?.shipping_cost) {
          total += parseFloat(orderData.order.shipping_cost);
        }
      }

      return total;
    };

    if (orderData) {
      let total = calculateTotal();
      setTotalBill(total);
    }
  }, [orderData]);

  // Parcelamento, em caso de cartão de crédito
  const getInstallments = () => {
    PagSeguroDirectPayment.getInstallments({
      amount: convertMonetary(totalBill, false),
      maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
      brand: state.creditCardBrand,
      success: function (response) {
        if (response.installments && state.creditCardBrand) {
          let creditCardBrandName = state.creditCardBrand
            .toLowerCase()
            .toString();

          if (response.installments[creditCardBrandName][0]) {
            setInstallments(response.installments[creditCardBrandName]);
          }

          if (
            response.installments[creditCardBrandName] &&
            response.installments[creditCardBrandName][0] &&
            response.installments[creditCardBrandName][0].quantity
          ) {
            setState({
              ...state,
              installmentQuantity: parseInt(
                response.installments[creditCardBrandName][0].quantity
              ),
              installmentValue:
                response.installments[creditCardBrandName][0].installmentAmount,
            });
          }
        }
      },
      error: function (response) {
        setState({
          ...state,
          installmentsFailure: true,
          searchInstallmentsComplete: true,
        });
      },
      complete: function (response) {
        setState({
          ...state,
          searchInstallmentsComplete: true,
        });
      },
    });
  };

  // Quando há uma troca de bandeira, precisamos buscar as condições de parcelamento
  useEffect(() => {
    if (
      state.creditCardBrand &&
      state.creditCardBrand.length &&
      PagSeguroDirectPayment &&
      !installments.length &&
      !state.searchInstallmentsComplete
    ) {
      getInstallments();
    }
  }, [
    state.creditCardBrand,
    state.searchInstallmentsComplete,
    PagSeguroDirectPayment,
    getInstallments,
  ]);

  const handleKeyDownChanges = (evt) => {
    evt = evt && evt.nativeEvent ? evt.nativeEvent : window.event;

    let name = evt.target.name;

    if (
      name === "creditCardCvv" ||
      name === "creditCardNumber" ||
      // name === "shippingAddressNumber" ||
      name === "senderPhone" ||
      name === "senderAreaCode"
    ) {
      return onlyNumbersFilter(evt);
    }

    if (name === "creditCardDate") {
      return monthYearMask(evt);
    }
  };

  const handleChangeInputs = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;

    if (name === "creditCardHolderName") {
      value = value.toUpperCase();
    }

    if (name === "senderDocument" || name === "creditCardHolderDocument") {
      // value = cpfMask(value);
      value = value.replace(/\D/g, "");
    }

    if (name === "creditCardNumber") {
      value = creditCardMask(value);
    }

    //handle change address inputs
    //change shipping and billing
    if (name === "shippingAddressStreet") {
      setState({
        ...state,
        maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
        noInterestInstallmentQuantity: maxInstallmentsNoInterest ?? 3,
        [evt.target.name]: value,
        billingAddressStreet: value,
      });
    } else if (name === "shippingAddressDistrict") {
      setState({
        ...state,
        maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
        noInterestInstallmentQuantity: maxInstallmentsNoInterest ?? 3,
        [evt.target.name]: value,
        billingAddressDistrict: value,
      });
    } else if (name === "shippingAddressNumber") {
      setState({
        ...state,
        maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
        noInterestInstallmentQuantity: maxInstallmentsNoInterest ?? 3,
        [evt.target.name]: value,
        billingAddressNumber: value,
      });
    } else if (name === "shippingAddressComplement") {
      setState({
        ...state,
        maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
        noInterestInstallmentQuantity: maxInstallmentsNoInterest ?? 3,
        [evt.target.name]: value,
        billingAddressComplement: value,
      });
    } else {
      setState({
        ...state,
        maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
        noInterestInstallmentQuantity: maxInstallmentsNoInterest ?? 3,
        [evt.target.name]: value,
      });
    }
  };

  const handleBlurInputs = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;

    if (name === "senderName") {
      let error = false;

      if (!value.length) {
        error = true;
      } else {
        let split = value.split(" ");
        if (split.length < 2) {
          error = true;
        } else {
          if (!split[1].length) {
            error = true;
          } else {
            error = false;
          }
        }
      }

      setState({
        ...state,
        senderNameError: error,
        senderName: value,
      });
    }

    if (name === "senderDocument") {
      let error = false;

      if (value.length < 11) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        senderDocumentError: error,
        senderDocument: value,
      });
    }

    if (name === "senderEmail") {
      const validateEmail = (email) => {
        const re =
          /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

      let error = false;

      if (!value.length) {
        error = true;
      } else {
        if (!validateEmail(value)) {
          error = true;
        } else {
          error = false;
        }
      }

      setState({
        ...state,
        senderEmailError: error,
        senderEmail: value,
      });
    }

    if (name === "senderAreaCode") {
      let error = false;

      if (value.length !== 2) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        senderAreaCodeError: error,
        senderAreaCode: value,
      });
    }

    if (name === "senderPhone") {
      let error = false;

      if (value.length < 7 || value.length > 9) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        senderPhoneError: error,
        senderPhone: value,
      });
    }

    if (
      name === "shippingAddressPostalCode" ||
      name === "billingAddressPostalCode"
    ) {
      if (value.length) {
        if (value.length !== 8) {
          if (name === "shippingAddressPostalCode") {
            setState({
              ...state,
              shippingAddressPostalCodeError: true,
              shippingAddressPostalCode: value,
            });
          } else {
            setState({
              ...state,
              billingAddressPostalCodeError: true,
              billinggAddressPostalCode: value,
            });
          }
        } else {
          value = value.replaceAll(" ", "");

          getAddress(value)
            .then(function (result) {
              if (result.data) {
                if (result.data.erro) {
                  if (name === "shippingAddressPostalCode") {
                    setState({
                      ...state,
                      shippingAddressPostalCodeError: true,
                      shippingAddressPostalCode: value,
                    });
                  } else {
                    setState({
                      ...state,
                      billingAddressPostalCodeError: true,
                      billingAddressPostalCode: value,
                    });
                  }
                } else {
                  let data = result.data;

                  if (name === "shippingAddressPostalCode") {
                    setState({
                      ...state,
                      shippingAddressDistrict: data.bairro,
                      shippingAddressStreet: data.logradouro,
                      shippingAddressComplement: data.complemento,
                      shippingAddressState: data.uf,
                      shippingAddressCity: data.localidade,
                      shippingAddressPostalCodeError: false,
                      shippingAddressPostalCode: value,
                    });
                  } else {
                    setState({
                      ...state,
                      billingAddressDistrict: data.bairro,
                      billingAddressStreet: data.logradouro,
                      billingAddressComplement: data.complemento,
                      billingAddressState: data.uf,
                      billingAddressCity: data.localidade,
                      billingAddressPostalCodeError: false,
                      billingAddressPostalCode: value,
                    });
                  }
                }
              }
            })
            .catch(function (error) {
              if (name === "shippingAddressPostalCode") {
                setState({
                  ...state,
                  shippingAddressPostalCodeError: false,
                  shippingAddressPostalCode: value,
                });
              } else {
                setState({
                  ...state,
                  billingAddressPostalCodeError: false,
                  billingAddressPostalCode: value,
                });
              }
            });
        }
      } else {
        if (name === "shippingAddressPostalCode") {
          setState({
            ...state,
            shippingAddressPostalCodeError: true,
          });
        } else {
          setState({
            ...state,
            billingAddressPostalCodeError: true,
          });
        }
      }
    }

    if (name === "shippingAddressStreet") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        shippingAddressStreetError: error,
        shippingAddressStreet: value,
      });
    }

    if (name === "billingAddressStreet") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        billingAddressStreetError: error,
        billingAddressStreet: value,
      });
    }

    if (name === "shippingAddressDistrict") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        shippingAddressDistrictError: error,
        shippingAddressDistrict: value,
      });
    }

    if (name === "billingAddressDistrict") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        billingAddressDistrictError: error,
        billingAddressDistrict: value,
      });
    }

    if (name === "shippingAddressNumber") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        shippingAddressNumberError: error,
        shippingAddressNumber: value,
      });
    }

    if (name === "billingAddressNumber") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        billingAddressNumberError: error,
        billingAddressNumber: value,
      });
    }

    if (name === "shippingAddressComplement") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        shippingAddressComplementError: error,
        shippingAddressComplement: value,
      });
    }

    if (name === "billingAddressComplement") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        billingAddressComplementError: error,
        billingAddressComplement: value,
      });
    }

    if (name === "shippingAddressCity") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        shippingAddressCityError: error,
        shippingAddressCity: value,
      });
    }

    if (name === "billingAddressCity") {
      let error = false;

      if (!value) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        billingAddressCityError: error,
        billingAddressCity: value,
      });
    }

    if (name === "creditCardDate") {
      let error = false;

      if (value.length === 5) {
        let split = value.split("/");

        if (split.length <= 1) {
          error = true;
        } else {
          if ((split[0] && parseInt(split[0]) > 12) || parseInt(split[0]) < 1) {
            error = true;
          }

          let month = split[0];
          if (month.length === 1) {
            month = "0" + month;
          }

          let year = split[1];
          if (year.length === 2) {
            year = "20" + year;
          }

          setState({
            ...state,
            creditCardDateError: false,
            expirationMonth: month,
            expirationYear: year,
            creditCardDate: value,
          });
        }
      } else {
        error = true;
      }

      setState({
        ...state,
        creditCardDateError: error,
        creditCardDate: value,
      });
    }

    if (name === "creditCardNumber") {
      if (value.replaceAll(" ", "").length < 16) {
        setState({
          ...state,
          creditCardCorrect: false,
          creditCardNumber: value,
        });
      } else {
        getBrand();
      }
    }

    if (name === "creditCardCvv") {
      let len = value.length;
      let error = false;

      if (len === 0 || len < state.creditCardCvvSize) {
        error = true;
      }

      setState({
        ...state,
        cvv: value,
        creditCardCvvError: error,
      });
    }

    if (name === "creditCardHolderDocument") {
      if (value.replaceAll(" ", "").length) {
        setState({
          ...state,
          creditCardHolderDocumentError: false,
          creditCardHolderDocument: value,
        });
      } else {
        setState({
          ...state,
          creditCardHolderDocumentError: true,
          creditCardHolderDocument: value,
        });
      }
    }

    if (name === "creditCardHolderName") {
      let error = false;

      if (!value.length) {
        error = true;
      } else {
        let split = value.split(" ");
        if (split.length <= 1) {
          error = true;
        } else {
          if (split[1].length) {
            error = false;
          } else {
            error = true;
          }
        }
      }

      setState({
        ...state,
        creditCardHolderNameError: error,
        creditCardHolderName: value,
      });
    }

    if (name === "creditCardHolderAreaCode") {
      let error = false;

      if (value.length !== 2) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        creditCardHolderAreaCodeError: error,
        creditCardHolderAreaCode: value,
      });
    }

    if (name === "creditCardHolderPhone") {
      let error = false;

      if (value.length < 7 || value.length > 9) {
        error = true;
      } else {
        error = false;
      }

      setState({
        ...state,
        creditCardHolderPhoneError: error,
        creditCardHolderPhonePhone: value,
      });
    }

    if (name === "creditCardHolderBirthDate") {
      let error = false;

      if (!value.length) {
        error = true;
      } else {
        let split = value.split("-");
        if (split.length !== 3) {
          error = true;
        } else {
          let month = split[1];
          let day = split[2];

          if (month > 12 || day > 31) {
            error = true;
          }
        }
      }

      setState({
        ...state,
        creditCardHolderBirthDate: value,
        creditCardHolderBirthDateError: error,
      });
    }
  };

  // Busca a bandeira do cartão de crédito
  const getBrand = () => {
    let creditCardNumber = Object.assign({}, state).creditCardNumber.replaceAll(
      " ",
      ""
    );

    if (creditCardNumber.length >= 6) {
      PagSeguroDirectPayment.getBrand({
        cardBin: creditCardNumber.substring(0, 6),
        success: function (response) {
          if (response && response.brand) {
            let newState = {};

            if (response.brand.name) {
              newState.creditCardBrand = response.brand.name;
            }

            if (response.brand.cvvSize) {
              newState.creditCardCvvSize = response.brand.cvvSize;
            }

            setState({
              ...state,
              ...newState,
              creditCardCorrect: true,
            });
          }
        },
        error: function (response) {
          setState({
            ...state,
            creditCardCvvSize: 3,
            creditCardBrand: null,
            creditCardCorrect: false,
          });
        },
      });
    } else {
      setState({
        ...state,
        creditCardBrand: "",
        creditCardCvvSize: 3,
        creditCardCorrect: false,
      });
    }
  };
  
  const toggleDetails = () => {
    setDetailsOpen(!isDetailsOpen);
  };

  // Monta a tabela de detalhes
  const mountDetailsDataTable = () => {
    const isPayment = orderData && orderData.order && orderData.order.isPayment;

    // para cobrança (pagamento)
    if (isPayment) {
      return (
        <>
          <Table responsive className="checkout-details-table">
            <thead>
              <tr>
                <th>Referente a</th>
                <th>Descrição</th>
                <th>Valor</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Cobrança</td>
                <td>{orderData.order.description}</td>
                <td>R$ {convertMonetary(totalBill)}</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <span className="checkout-total-price">
            Total: R$ {convertMonetary(totalBill)}
          </span>
        </>
      );

      // em caso de pedidos
    } else {
      if (orderData && orderData.order && orderData.order.items) {
        let products = orderData.order.items.map((product, key) => {
          return (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{product.name}</td>
              <td>R$ {convertMonetary(product?.price)}</td>
              <td>{product.quantity}</td>
              <td>R$ {convertMonetary(product?.price * product?.quantity)}</td>
            </tr>
          );
        });

        if (orderData.order.shipping_cost) {
          products.push(
            <tr>
              <td>-</td>
              <td>Frete</td>
              <td>R$ {convertMonetary(orderData.order.shipping_cost)}</td>
              <td>-</td>
              <td>R$ {convertMonetary(orderData.order.shipping_cost)}</td>
            </tr>
          );
        }

        return (
          <>
            <Table responsive className="checkout-details-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Quantidade</th>
                  <th>Total</th>
                </tr>
              </thead>

              <tbody>{products}</tbody>
            </Table>
            <hr />
            <span className="checkout-total-price">
              Total: R$ {convertMonetary(totalBill)}
            </span>
          </>
        );
      } else {
        return <div>Nenhum item encontrado</div>;
      }
    }
  };

  // Mostra o formulário de acordo com a etapa atual
  const loadFormStep = () => {
    switch (formStep) {
      case FORM_STEP_PERSONAL_DATA:
        return (
          <Container>
            <Row xs="1" sm="1" md="2">
              <Col>
                <h5 style={{ textAlign: "initial" }}>Dados Pessoais</h5>
              </Col>
            </Row>

            <Row xs="1" sm="1" md="1" lg="2">
              <Col className="checkout-form-col">
                <Label>Nome completo: </Label>
                <Input
                  invalid={state.senderNameError === true}
                  type="text"
                  autoFocus={true}
                  placeholder="Nome Completo *"
                  name="senderName"
                  value={state.senderName}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                  maxLength={50}
                />
                {state.senderNameError && (
                  <>
                    <FormFeedback>
                      O nome do comprador é obrigatório e deve ser composto por
                      dois nomes.
                    </FormFeedback>
                  </>
                )}
              </Col>
              <Col className="checkout-form-col">
                <Label>CPF/CNPJ: </Label>
                <Input
                  invalid={state.senderDocumentError === true}
                  type="text"
                  placeholder="CPF/CNPJ *"
                  name="senderDocument"
                  value={state.senderDocument}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                />
                {state.senderDocumentError && (
                  <>
                    <FormFeedback>
                      O CPF/CNPJ do comprador é obrigatório.
                    </FormFeedback>
                  </>
                )}
              </Col>
            </Row>

            <Row xs="1" sm="1" md="1" lg="2">
              <Col className="checkout-form-col">
                <Label>E-mail: </Label>
                <Input
                  invalid={state.senderEmailError === true}
                  type="email"
                  placeholder="E-mail *"
                  name="senderEmail"
                  value={state.senderEmail}
                  onChange={handleChangeInputs}
                  onBlur={handleBlurInputs}
                />
                {state.senderEmailError && (
                  <FormFeedback>
                    O E-mail do comprador é obrigatório e deve estar no formato
                    de um e-mail válido.
                  </FormFeedback>
                )}
              </Col>

              <Col xs="4" sm="4" md="4" lg="2" className="checkout-form-col">
                <Label className="left">DDD: </Label>
                <Input
                  type="text"
                  maxLength={2}
                  placeholder="DDD *"
                  name="senderAreaCode"
                  value={state.senderAreaCode}
                  //onKeyDown={isNumber}
                  onChange={handleChangeInputs}
                  onKeyDown={handleKeyDownChanges}
                  onBlur={handleBlurInputs}
                  invalid={state.senderAreaCodeError}
                />
                {state.senderAreaCodeError && (
                  <>
                    <FormFeedback>
                      O DDD do comprador é obrigatório e deve ter 2 dígitos.
                    </FormFeedback>
                  </>
                )}
              </Col>

              <Col xs="8" sm="8" md="8" lg="4" className="checkout-form-col">
                <Label>Telefone: </Label>
                <Input
                  type="text"
                  placeholder="Telefone *"
                  name="senderPhone"
                  value={state.senderPhone}
                  onChange={handleChangeInputs}
                  onKeyDown={handleKeyDownChanges}
                  onBlur={handleBlurInputs}
                  invalid={state.senderPhoneError}
                  maxLength={9}
                />
                {state.senderPhoneError && (
                  <>
                    <FormFeedback>
                      O telefone do comprador é obrigatório e deve ter de 7 a 9
                      dígitos.
                    </FormFeedback>
                  </>
                )}
              </Col>
            </Row>
            {/* <br /> */}
            {orderData && orderData.order && orderData.order.address ? (
              <>
                <Row xs="1" sm="1" md="1" lg="2">
                  <Col>
                    <h5 style={{ textAlign: "initial" }}>Dados da Entrega</h5>
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="1" lg="2">
                  <Col className="checkout-form-col">
                    <Label>CEP: </Label>
                    <Input
                      type="text"
                      placeholder="CEP *"
                      name="shippingAddressPostalCode"
                      value={state.shippingAddressPostalCode}
                      onChange={handleChangeInputs}
                      onBlur={handleBlurInputs}
                      disabled={true}
                      maxLength={8}
                      invalid={state.shippingAddressPostalCodeError}
                    />

                    {state.shippingAddressPostalCodeError && (
                      <>
                        <FormFeedback>Este CEP é inválido.</FormFeedback>
                      </>
                    )}
                  </Col>
                  <Col className="checkout-form-col">
                    <Label>Endereço: </Label>
                    <Input
                      // disabled={true}
                      required
                      type="text"
                      placeholder="Endereço *"
                      value={state.shippingAddressStreet}
                      onChange={handleChangeInputs}
                      name="shippingAddressStreet"
                      onBlur={handleBlurInputs}
                      invalid={state.shippingAddressStreetError === true}
                    />
                    {state.shippingAddressStreetError && (
                      <>
                        <FormFeedback>
                          O campo endereço é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="1" lg="2">
                  <Col className="checkout-form-col">
                    <Label>Bairro: </Label>
                    <Input
                      type="text"
                      name="shippingAddressDistrict"
                      value={state.shippingAddressDistrict}
                      onChange={handleChangeInputs}
                      placeholder="Bairro *"
                      onBlur={handleBlurInputs}
                      // disabled={true}
                      required
                      invalid={state.shippingAddressDistrictError === true}
                    />
                    {state.shippingAddressDistrictError && (
                      <>
                        <FormFeedback>
                          O campo Bairro é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                  <Col
                    xs="10"
                    sm="6"
                    md="6"
                    lg="4"
                    className="checkout-form-col"
                  >
                    <Label>Número:</Label>
                    <Input
                      type="text"
                      placeholder="Número *"
                      name="shippingAddressNumber"
                      value={state.shippingAddressNumber}
                      onChange={handleChangeInputs}
                      onKeyDown={handleKeyDownChanges}
                      onBlur={handleBlurInputs}
                      // disabled={true}
                      invalid={state.shippingAddressNumberError}
                      maxLength={20}
                      required
                    />
                    {state.shippingAddressNumberError && (
                      <>
                        <FormFeedback>
                          O campo Número é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="10"
                    lg="10"
                    className="checkout-form-col"
                  >
                    <Label>Complemento:</Label>
                    <Input
                      type="text"
                      placeholder="Complemento *"
                      name="shippingAddressComplement"
                      value={state.shippingAddressComplement}
                      onChange={handleChangeInputs}
                      onBlur={handleBlurInputs}
                      // disabled={true}
                      invalid={state.shippingAddressComplementError}
                    />
                    {state.shippingAddressComplementError && (
                      <>
                        <FormFeedback>
                          O campo Complemento é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                </Row>

                <Row xs="1" sm="1" md="2">
                  <Col className="checkout-form-col">
                    <Label>Estado: </Label>
                    <Input
                      type="select"
                      onChange={handleChangeInputs}
                      name="shippingAddressState"
                      value={state.shippingAddressState}
                      disabled={true}
                    >
                      <option value="">Estado</option>
                      {Object.keys(addressStateList).map((key, index) => {
                        return (
                          <option key={index} value={key}>
                            {addressStateList[key]}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col className="checkout-form-col">
                    <Label>Cidade: </Label>
                    <Input
                      type="text"
                      name="shippingAddressCity"
                      value={state.shippingAddressCity}
                      onChange={handleChangeInputs}
                      placeholder="Cidade *"
                      onBlur={handleBlurInputs}
                      invalid={state.shippingAddressCityError}
                      maxLength={60}
                      disabled={true}
                    />
                    {state.shippingAddressCityError && (
                      <>
                        <FormFeedback>
                          O campo Cidade é obrigatório.
                        </FormFeedback>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}

            {/* <br /> */}
            <Row xs="1" sm="1" md="1">
              <Col>
                <SubmitButton
                  disabled={!isContinueButtonEnabled}
                  size="lg"
                  color="secondary"
                  text="Continuar"
                  title="Preencha todos os campos antes de continuar"
                  onClick={() => {
                    let newState = Object.assign({}, state);
                    newState.creditCardHolderDocument = newState.senderDocument;
                    newState.creditCardHolderAreaCode = newState.senderAreaCode;
                    newState.creditCardHolderPhone = newState.senderPhone;
                    handleSubmitContactChangeShippingAddress();
                    setState({
                      ...state,
                      ...newState,
                    });
                    setFormStep(FORM_STEP_PAYMENT_METHOD);
                  }}
                />
              </Col>
            </Row>
          </Container>
        );

      case FORM_STEP_PAYMENT_METHOD:
        const paymentMethodName = (name) => {
          switch (name) {
            case "BALANCE":
              return "Saldo Pagseguro";

            case "CREDIT_CARD":
              return "Cartão de Crédito";

            case "BOLETO":
              return "Boleto";

            case "DEPOSIT":
              return "Depósito";

            case "ONLINE_DEBIT":
              return "Débito";

            default:
              break;
          }
        };

        // carrega o select com as formas de pagamento
        const loadPaymentMethods = () => {
          if (paymentMethods) {
            return Object.keys(paymentMethods).map((key, index) => {
              let expected_name = null;
              /**
               * por algum motivo, a API do Pagseguro nos retorna os nomes dos métodos de pagamento de uma maneira diferente do que eles querem receber na hora de enviar os dados
               * portanto, precisamos fazer uma conversão de nomes
               */
              switch (paymentMethods[key].name) {
                case "CREDIT_CARD":
                  expected_name = "creditCard";
                  break;

                case "BOLETO":
                  expected_name = "boleto";
                  break;

                default:
                  expected_name = null;
                  break;
              }

              return (
                <option value={expected_name} key={index}>
                  {paymentMethodName(paymentMethods[key].name)}
                </option>
              );
            });
          } else {
            return <option value="creditCard">Cartão de Crédito</option>;
          }
        };

        return (
          <Container>
            <Row xs="1" sm="1" md="1" lg="2">
              <Col>
                <h5 style={{ textAlign: "initial" }}>Dados de Pagamento</h5>
              </Col>
            </Row>
            <Row xs="1" sm="1" md="1" lg="2">
              <Col className="checkout-form-col">
                <Label>Forma de pagamento</Label>
                <Input
                  type="select"
                  name="paymentMethod"
                  onChange={handleChangeInputs}
                  value={state.paymentMethod}
                >
                  <option value="">Escolha</option>
                  {loadPaymentMethods()}
                  {/* <option value="creditCard">Cartão de Crédito</option>
                  <option value="eft">Cartão de Débito</option>
                  <option value="boleto">Boleto Bancário</option> */}
                </Input>
              </Col>
            </Row>

            {loadPaymentForm()}

            {/* <br /> */}
            <Row xs="1" sm="1" md="1" lg="3" xl="2">
              <Col className="checkout-form-col-buttons" lg="8">
                <SubmitButton
                  size="lg"
                  color="light"
                  text="Voltar"
                  onClick={() => setFormStep(FORM_STEP_PERSONAL_DATA)}
                />

                <SubmitButton
                  size="lg"
                  color="secondary"
                  text={"Finalizar Pedido"}
                  onClick={() => onSubmit()}
                  loading={isSubmitButtonLoading.toString()}
                  disabled={!isFinishButtonEnabled}
                  style={{ textTransform: "uppercase" }}
                  title="Preencha todos os campos obrigatórios."
                />
              </Col>
            </Row>
          </Container>
        );

      case FORM_STEP_FINISHED_PAYMENT:
        if (state.paymentMethod === "creditCard") {
          return (
            <Container>
              <Row>
                <Col>
                  <h3 style={{ color: "var(--success)" }}>
                    Agradecemos a preferência!
                  </h3>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <p>Seu pedido será entregue em breve.</p>
                </Col>
              </Row>
            </Container>
          );
        } else if (state.paymentMethod === "boleto") {
          return (
            <Container>
              <Row>
                <Col>
                  <h3 style={{ color: "var(--success)" }}>
                    Agradecemos a preferência!
                  </h3>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <p>
                    Registramos seu pedido com sucesso. Após o pagamento do
                    boleto, seu pedido será encaminhado em até 2 dias úteis.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>{loadBilletForm()}</Col>
              </Row>
            </Container>
          );
        }

        break;

      default:
        break;
    }
  };

  const loadPaymentForm = () => {
    switch (state.paymentMethod) {
      case "creditCard":
        return loadCreditCardForm();

      case "eft":
        break;
      case "BALANCE":
        break;
      case "DEPOSIT":
        break;
      default:
        return;
    }
  };

  const loadBilletForm = () => {
    return (
      <>
        <Row className="billet-form-row">
          <Col className="billet-form-col">
            <div className="billet-form">
              <iframe
                className="billet-form-iframe"
                src={paymentLink}
                title="Boleto"
              ></iframe>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const loadCreditCardForm = () => {
    // carrega as imagens de bandeiras de cartão de crédito
    const loadBrandImages = () => {
      if (
        paymentMethods &&
        paymentMethods.CREDIT_CARD &&
        paymentMethods.CREDIT_CARD.options
      ) {
        let options = [];

        options = paymentMethods.CREDIT_CARD.options;

        if (state.creditCardBrand && state.creditCardBrand.length) {
          options = Object.keys(options)
            .filter((key) => {
              return (
                options[key].name.toUpperCase() ===
                state.creditCardBrand.toUpperCase()
              );
            })
            .reduce((obj, key) => {
              obj[key] = options[key];
              return obj;
            }, {});
        }

        return Object.keys(options).map((key, index) => {
          return (
            <img
              key={index}
              alt="Bandeiras de cartão de crédito"
              title={options[key].name}
              src={pagSeguroCDNBaseURI + options[key].images.SMALL.path}
            />
          );
        });
      }
    };

    // carrega as condições de parcelamento
    const loadInstallmentOptions = () => {
      if (installments) {
        let installmentOptions = Object.assign({}, installments);

        return Object.keys(installmentOptions).map((key, index) => {
          return (
            <option key={index} value={installmentOptions[key].quantity}>
              {installmentOptions[key].quantity}x de R${" "}
              {installmentOptions[key].installmentAmount
                .toFixed(2)
                .replaceAll(".", ",")}{" "}
              {installmentOptions[key].interestFree === true
                ? " (sem juros) "
                : ""}
            </option>
          );
        });
      }

      return <>Carregando opções...</>;
    };

    const loadAddressFields = () => {
      const hasAddress =
        orderData && orderData.order && orderData.order.address;

      return (
        <>
          {hasAddress && (
            <Row>
              <Col className="checkout-form-col">
                <Label className="checkout-owner-label" check>
                  <Input
                    type="checkbox"
                    checked={isCreditCardAddressFieldsShow ? false : true}
                    onChange={toggleCreditCardAddressFieldsShow}
                  />
                  Endereço de cobrança do cartão são iguais ao endereço de
                  entrega
                </Label>
              </Col>
            </Row>
          )}

          {(isCreditCardAddressFieldsShow || !hasAddress) && (
            <>
              {/* Endereço de cobrança do cartão  */}
              <Row xs="1" sm="1" md="1" lg="2">
                <Col xs="12" lg="12">
                  <h6 style={{ textAlign: "initial" }}>
                    Endereço de cobrança do cartão
                  </h6>
                </Col>
                <Col className="checkout-form-col">
                  <Label>CEP:</Label>
                  <Input
                    type="text"
                    placeholder="CEP *"
                    name="billingAddressPostalCode"
                    value={state.billingAddressPostalCode}
                    onChange={handleChangeInputs}
                    onBlur={handleBlurInputs}
                    maxLength={8}
                    invalid={state.billingAddressPostalCodeError}
                  />

                  {state.billingAddressPostalCodeError && (
                    <>
                      <FormFeedback>Este CEP é inválido.</FormFeedback>
                    </>
                  )}
                </Col>
                <Col className="checkout-form-col">
                  <Label>Endereço:</Label>
                  <Input
                    type="text"
                    placeholder="Endereço *"
                    value={state.billingAddressStreet}
                    onChange={handleChangeInputs}
                    name="billingAddressStreet"
                    onBlur={handleBlurInputs}
                    invalid={state.billingAddressStreetError === true}
                  />
                  {state.billingAddressStreetError && (
                    <>
                      <FormFeedback>
                        O campo endereço é obrigatório.
                      </FormFeedback>
                    </>
                  )}
                </Col>
              </Row>

              <Row xs="1" sm="1" md="1" lg="2">
                <Col className="checkout-form-col">
                  <Label>Bairro:</Label>
                  <Input
                    type="text"
                    name="billingAddressDistrict"
                    value={state.billingAddressDistrict}
                    onChange={handleChangeInputs}
                    placeholder="Bairro *"
                    onBlur={handleBlurInputs}
                    invalid={state.billingAddressDistrictError === true}
                  />
                  {state.billingAddressDistrictError && (
                    <>
                      <FormFeedback>O campo Bairro é obrigatório.</FormFeedback>
                    </>
                  )}
                </Col>
                <Col xs="10" sm="6" md="6" lg="4" className="checkout-form-col">
                  <Label>Número:</Label>
                  <Input
                    type="text"
                    placeholder="Número *"
                    name="billingAddressNumber"
                    value={state.billingAddressNumber}
                    onChange={handleChangeInputs}
                    onKeyDown={handleKeyDownChanges}
                    onBlur={handleBlurInputs}
                    invalid={state.billingAddressNumberError}
                    maxLength={20}
                  />
                  {state.billingAddressNumberError && (
                    <>
                      <FormFeedback>O campo Número é obrigatório.</FormFeedback>
                    </>
                  )}
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="10"
                  lg="10"
                  className="checkout-form-col"
                >
                  <Label>Complemento:</Label>
                  <Input
                    type="text"
                    placeholder="Complemento *"
                    name="billingAddressComplement"
                    value={state.billingAddressComplement}
                    onChange={handleChangeInputs}
                    onBlur={handleBlurInputs}
                    invalid={state.billingAddressComplementError}
                  />
                  {state.billingAddressComplementError && (
                    <>
                      <FormFeedback>
                        O campo Complemento é obrigatório.
                      </FormFeedback>
                    </>
                  )}
                </Col>
              </Row>

              <Row xs="1" sm="1" md="2">
                <Col className="checkout-form-col">
                  <Label>Estado: </Label>
                  <Input
                    type="select"
                    onChange={handleChangeInputs}
                    name="billingAddressState"
                    value={state.billingAddressState}
                  >
                    <option value="">Estado</option>
                    {Object.keys(addressStateList).map((key, index) => {
                      return (
                        <option key={index} value={key}>
                          {addressStateList[key]}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
                <Col className="checkout-form-col">
                  <Label>Cidade: </Label>
                  <Input
                    type="text"
                    name="billingAddressCity"
                    value={state.billingAddressCity}
                    onChange={handleChangeInputs}
                    placeholder="Cidade *"
                    onBlur={handleBlurInputs}
                    invalid={state.billingAddressCityError}
                    maxLength={60}
                  />
                  {state.billingAddressCityError && (
                    <>
                      <FormFeedback>O campo Cidade é obrigatório.</FormFeedback>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      );
    };

    return (
      <>
        <Row xs="1" sm="1" md="1" lg="2">
          <Col className="checkout-form-col">
            <Label>Número do cartão:</Label>
            <Input
              invalid={state.creditCardCorrect === false}
              type="text"
              name="creditCardNumber"
              value={state.creditCardNumber}
              onChange={handleChangeInputs}
              onKeyDown={handleKeyDownChanges}
              onBlur={handleBlurInputs}
              placeholder="Número do Cartão *"
              maxLength={19}
            />
            {state.creditCardCorrect === false && (
              <FormFeedback>Número de cartão de crédito inválido</FormFeedback>
            )}
          </Col>
          <Col className="checkout-form-col">{loadBrandImages()}</Col>
        </Row>
        <Row xs="1" sm="1" md="2" lg="3">
          <Col className="checkout-form-col" xs="6" xl="4">
            <Label>Vencimento:</Label>
            <Input
              invalid={state.creditCardDateError === true}
              type="text"
              name="creditCardDate"
              value={state.creditCardDate}
              onChange={handleChangeInputs}
              onKeyDown={handleKeyDownChanges}
              onBlur={handleBlurInputs}
              placeholder="Vencimento *"
              maxLength={5}
            />
            {state.creditCardDateError === true && (
              <FormFeedback>Data de vencimento inválida</FormFeedback>
            )}
          </Col>
          <Col className="checkout-form-col" xs="6" xl="4">
            <Label>CVV:</Label>
            <Input
              invalid={state.creditCardCvvError === true}
              type="text"
              name="creditCardCvv"
              value={state.creditCardCvv}
              onChange={handleChangeInputs}
              placeholder="CVV *"
              onKeyDown={handleKeyDownChanges}
              onBlur={handleBlurInputs}
              maxLength={state.creditCardCvvSize ? state.creditCardCvvSize : 3}
            />
            {state.creditCardCvvError === true && (
              <FormFeedback>
                O tamanho do CVV deve ser de {state.creditCardCvvSize} dígitos.
              </FormFeedback>
            )}
          </Col>
        </Row>

        {includeInstallment !== ONE_TIME_PAYMENT && installments && (
          <Row xs="1" sm="1" md="2">
            <Col className="checkout-form-col">
              <Label>Parcelas</Label>
              <Input
                type="select"
                value={state.installmentQuantity}
                onChange={(e) => {
                  setState({
                    ...state,
                    installmentQuantity: parseInt(e.target.value),
                    installmentValue: installments.find(
                      (installment) =>
                        installment.quantity === parseInt(e.target.value)
                    ).installmentAmount,

                    maxInstallmentNoInterest: maxInstallmentsNoInterest ?? 3,
                    noInterestInstallmentQuantity:
                      maxInstallmentsNoInterest ?? 3,
                  });
                }}
              >
                {loadInstallmentOptions()}
              </Input>
            </Col>
          </Row>
        )}

        <Row xs="1" sm="1" md="1" lg="2">
          <Col className="checkout-form-col">
            <Label>Nome impresso no cartão:</Label>
            <Input
              type="text"
              placeholder="Nome impresso no Cartão *"
              name="creditCardHolderName"
              value={state.creditCardHolderName}
              onChange={handleChangeInputs}
              onBlur={handleBlurInputs}
              invalid={state.creditCardHolderNameError}
            />
            {state.creditCardHolderNameError === true && (
              <FormFeedback>
                O nome impresso no cartão é obrigatório e deve ter pelo menos
                duas palavras.
              </FormFeedback>
            )}
          </Col>

          {state.senderDocument.length == 11 ? (
            <Col className="checkout-form-col card-owner-col">
              <Label className="checkout-owner-label" check>
                <Input
                  type="checkbox"
                  checked={isCreditCardOwnerShipmentFieldsShow ? true : false}
                  onChange={toggleCreditCardOwnerShipmentFields}
                />
                Sou o proprietário do cartão
              </Label>
            </Col>
          ) : null}
        </Row>

        <Row xs="1" sm="1" md="1" lg="2">
          {isCreditCardOwnerShipmentFieldsShow === false ||
          state.senderDocument.length == 14 ? (
            <Col xs="12" lg="12">
              <h6 style={{ textAlign: "initial" }}>Dados do dono do cartão</h6>
            </Col>
          ) : null}
          <Col className="checkout-form-col">
            <Label>Data de nascimento</Label>
            <Input
              type="date"
              placeholder="Dt Nasc.*"
              name="creditCardHolderBirthDate"
              value={state.creditCardHolderBirthDate}
              onChange={handleChangeInputs}
              title="Data de nascimento do dono do cartão"
              maxLength={10}
              invalid={state.creditCardHolderBirthDateError === true}
              onBlur={handleBlurInputs}
            />
            {state.creditCardHolderBirthDateError === true && (
              <FormFeedback>
                A data de nascimento do dono do cartão é obrigatória e está
                inválida.
              </FormFeedback>
            )}
          </Col>
        </Row>

        {(isCreditCardOwnerShipmentFieldsShow === false ||
          state.senderDocument.length == 14) && (
          <Row xs="1" sm="1" md="1" lg="2">
            <Col className="checkout-form-col">
              <Label>CPF do dono do cartão:</Label>
              <Input
                type="text"
                placeholder="CPF do dono do cartão *"
                name="creditCardHolderDocument"
                value={state.creditCardHolderDocument}
                onChange={handleChangeInputs}
                onBlur={handleBlurInputs}
                invalid={state.creditCardHolderDocumentError}
              />
              {state.creditCardHolderDocumentError === true && (
                <FormFeedback>CPF obrigatório</FormFeedback>
              )}
            </Col>
            <Col xs="4" sm="4" md="4" lg="2" className="checkout-form-col">
              <Label>DDD:</Label>
              <Input
                type="text"
                maxLength={2}
                placeholder="DDD do telefone do dono do cartão*"
                name="creditCardHolderAreaCode"
                value={state.creditCardHolderAreaCode}
                onChange={handleChangeInputs}
                onKeyDown={handleKeyDownChanges}
                onBlur={handleBlurInputs}
                invalid={state.creditCardHolderAreaCodeError}
              />
              {state.creditCardHolderAreaCodeError && (
                <>
                  <FormFeedback>
                    O DDD do dono do cartão é obrigatório e deve ter 2 dígitos.
                  </FormFeedback>
                </>
              )}
            </Col>

            <Col xs="8" sm="8" md="8" lg="4" className="checkout-form-col">
              <Label>Telefone:</Label>
              <Input
                type="text"
                placeholder="Telefone do dono do Cartão*"
                name="creditCardHolderPhone"
                value={state.creditCardHolderPhone}
                onChange={handleChangeInputs}
                onKeyDown={handleKeyDownChanges}
                onBlur={handleBlurInputs}
                invalid={state.creditCardHolderPhoneError}
                maxLength={9}
              />
              {state.creditCardHolderPhoneError && (
                <>
                  <FormFeedback>
                    O telefone do dono do cartão é obrigatório e deve ter de 7 a
                    9 dígitos.
                  </FormFeedback>
                </>
              )}
            </Col>
          </Row>
        )}
        {loadAddressFields()}
      </>
    );
  };

  const toggleCreditCardOwnerShipmentFields = () => {
    setCreditCardOwnerShipmentFields(!isCreditCardOwnerShipmentFieldsShow);
  };

  const toggleCreditCardAddressFieldsShow = () => {
    setCreditCardAddressFields(!isCreditCardAddressFieldsShow);
  };

  // Ao submeter o formulário
  const onSubmit = () => {
    setSubmitButtonLoading(true);
  };

  const createCreditCardHolderData = (obj) => {
    obj.creditCardHolderCPF = obj.creditCardHolderDocument;

    obj.creditCardHolderDocument = obj.creditCardHolderDocument.replace(
      /\D/g,
      ""
    );
    obj.creditCardHolderAreaCode = obj.creditCardHolderAreaCode.replace(
      /\D/g,
      ""
    );
    obj.creditCardHolderPhone = obj.creditCardHolderPhone.replace(/\D/g, "");

    let split = obj.creditCardHolderBirthDate.split("-");
    if (split.length === 3) {
      let year = split[0];
      let month = split[1];
      let day = split[2];

      let newDate = day + "/" + month + "/" + year;

      obj.creditCardHolderBirthDate = newDate;
    }

    return obj;
  };

  //  remove alguns atributos que não são essenciais para submeter o objeto ao backend
  const deleteUncessaryStateAttributes = (obj) => {
    // remove alguns itens desnecessários e perigosos antes de enviar para o servidor
    if (obj.creditCardDate) {
      delete obj.creditCardDate;
    }

    if (obj.creditCardNumber) {
      delete obj.creditCardNumber;
    }

    if (obj.creditCardCvv) {
      delete obj.creditCardCvv;
    }

    if (obj.creditCardDateError) {
      delete obj.creditCardDateError;
    }

    if (obj.shippingAddressPostalCodeError) {
      delete obj.shippingAddressPostalCodeError;
    }

    if (!obj.extraAmount) {
      delete obj.extraAmount;
    }

    if (!obj.shippingAddressRequired) {
      delete obj.shippingAddressStreet;
      delete obj.shippingAddressNumber;
      delete obj.shippingAddressComplement;
      delete obj.shippingAddressDistrict;
      delete obj.shippingAddressPostalCode;
      delete obj.shippingAddressCity;
      delete obj.shippingAddressState;
      delete obj.shippingAddressCountry;
      delete obj.shippingType;
      delete obj.shippingCost;
    }

    delete obj.shippingAddressCityError;
    delete obj.shippingAddressComplementError;
    delete obj.shippingAddressNumberError;
    delete obj.shippingAddressPostalCodeError;
    delete obj.shippingAddressStreetError;
    delete obj.shippingAddressDistrictError;
    delete obj.senderPhoneError;
    delete obj.senderEmailError;
    delete obj.senderNameError;
    delete obj.senderAreaCodeError;

    delete obj.creditCardHolderDocument;

    if (obj.paymentMethod !== "creditCard") {
      delete obj.creditCardToken;
      delete obj.creditCardNumber;
      delete obj.creditCardMask;
      delete obj.creditCardHolderAreaCode;
      delete obj.creditCardHolderPhone;
      delete obj.creditCardHolderName;
      delete obj.creditCardHolderBirthDate;
      delete obj.creditCardCvv;
      delete obj.creditCardBrand;
      delete obj.billingAddressCity;
      delete obj.billingAddressComplement;
      delete obj.billingAddressCountry;
      delete obj.billingAddressDistrict;
      delete obj.billingAddressNumber;
      delete obj.billingAddressPostalCode;
      delete obj.billingAddressState;
      delete obj.billingAddressStreet;
      delete obj.installmentValue;
      delete obj.installmentQuantity;
    }

    return obj;
  };

  // Limpar todos os campos ou redirecionar após o pagamento ser realizado com sucesso
  const finishPayment = () => {
    setFormStep(FORM_STEP_FINISHED_PAYMENT);
  };

  const handleSubmitContactChangeShippingAddress = () => {
    //Order has shipping address
    if (orderData && orderData.order && orderData.order.address) {
      let _orderAddress = orderData.order.address;
      let _stateAddress = {
        address: state.shippingAddressStreet,
        number: state.shippingAddressNumber,
        complement: state.shippingAddressComplement,
        district: state.shippingAddressDistrict,
        cep: state.shippingAddressPostalCode,
        city: state.shippingAddressCity,
        state: state.shippingAddressState,
        country: state.shippingAddressCountry,
      };
      // orderData.address and state.shippingAddres[values] hare diffents
      if (
        _orderAddress.address != _stateAddress.address ||
        _orderAddress.complement != _stateAddress.complement ||
        _orderAddress.district != _stateAddress.district ||
        _orderAddress.number != _stateAddress.number
      ) {
        //send request from shopping backend
        submitOrderAddress({
          address: _stateAddress,
          orderId: orderData?.order?.id,
          token: tkn,
        })
          .then(function (success) {})
          .catch(function (error) {});
      }
    }
  };

  return (
    
    
    <div className="checkout-container grid-template">
      <div className="footer-content header-grid">
        <img alt="example" src={imageSegura} />
      </div>
      {status === STATUS_ORDER_PROCESSED ? (
        <div className="checkout-order-processed content-grid">
          <Icon
            color="var(--primary)"
            size={52}
            position={"relative"}
            top={"-2px"}
            icon="poli-icon pi-clock-fill"
          />
          <br />
          <br />
          <h3>Esta Transação já foi processada.</h3>
        </div>
      ) : status === STATUS_ORDER_PROCESSED ? (
        <></>
        ) : status === STATUS_LOADING ? (
          <div className="checkout-loading-container content-grid">
            <Loading />
          </div>
        ) : status === STATUS_SERVER_OFFLINE ? (
       <div className="checkout-container content-grid">
          <h3>Esta Transação não está disponível.</h3>
        </div>
      ) : status === STATUS_GATEWAY_OFFLINE ? (
        <div className="checkout-container content-grid">
          <h3> Serviço temporariamente indisponível.</h3>
        </div>
      ) : (
        // <div className="checkout-container content-grid">
        <div className="content-grid">
          {/* Modal de sucesso */}
          <ModalStatus
            size={"lg"}
            type="success"
            title="Processo Concluído! Pagamento em análise!"
            subtitle="Logo você receberá o seu pedido."
            isOpen={isSuccessModalOpen}
            toggle={(isSuccessModalOpen) => {
              setSuccessModalOpen(!isSuccessModalOpen);
              finishPayment();
            }}
          ></ModalStatus>

          <ModalStatus
            size={"lg"}
            type="danger"
            title="Erro ao processar o pagamento"
            subtitle={messageErroPayment}
            isOpen={isFailureModalOpen}
            toggle={(isFailureModalOpen) => {
              setFailureModalOpen(!isFailureModalOpen);
            }}
          ></ModalStatus>

          {/* <div className="checkout-safe-div">
            <div className="checkout-safe-div-icon">
              <Icon
                color="#FFFFFF"
                size={32}
                icon="poli-icon pi-password-fill"
              />
            </div>

            <span className="checkout-safe-text">Checkout Seguro</span>
          </div> */}

          <div className="checkout-main-content">
            <div className="checkout-main-content-header">
              <div className="checkout-main-content-header-image">
                {customerPicture ? (
                  <img
                    alt={customerName ? customerName : "Imagem do cliente"}
                    src={customerPicture}
                    onError={() => setCustomerPicture(null)}
                    height="30px"
                  />
                ) : (
                  <div className="checkout-main-content-header-name">
                    <div className="header-polichat-polipay">
                      <img className="poli-logo" src={logoPoli} alt="Poli logo" height={100} width={100}/>
                      <span>+</span>
                    </div>
                    <span className="header-customer-name">{customerName}</span>
                  </div>
                )}
              </div>
              {formStep !== FORM_STEP_FINISHED_PAYMENT && (
                <div className="checkout-main-content-header-collapse">
                  <Modal
                    size={"lg"}
                    isOpen={isDetailsOpen}
                    toggle={toggleDetails}
                  >
                    <ModalHeader toggle={toggleDetails}>Detalhes</ModalHeader>
                    <ModalBody>{mountDetailsDataTable()}</ModalBody>
                  </Modal>

                  <Button
                    color="link"
                    onClick={toggleDetails}
                    style={{ marginBottom: "1rem" }}
                  >
                    R$ {convertMonetary(totalBill)} - Ver detalhes
                  </Button>
                </div>
              )}
            </div>

            <div className="checkout-main-content-body">
              <div className="checkout-main-content-body-form">
                <div className="checkout-body-header">
                  {formStep !== FORM_STEP_FINISHED_PAYMENT && (
                    <>
                      <div className="body-header-container">
                        <span className="checkout-body-header-subtitle">
                          Não compartilhamos seus dados financeiros com ninguém.
                        </span>
                        <Icon
                          id="locked-icon"
                          color="var(--primary)"
                          size={22}
                          icon="poli-icon pi-password-fill"
                        />
                      </div>
                      <span className="checkout-body-header-title">
                        Finalize seu pedido
                      </span>
                    </>
                  )}
                </div>

                <div className="checkout-body-content">{loadFormStep()}</div>
              </div>

              <div className="checkout-main-content-body-image">
                <img
                  alt="example"
                  src={imageBackground}
                  height="276"
                  width="250"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="footer-all footer-grid">
        <a
          href="https://polichat.com.br/"
          target="blank"
          style={{ textDecoration: "none" }}
        >
          <span>Powered by</span>
          <img alt="example" src={logoPoli} height="30" />
          <span>{version}</span>
        </a>
      </div>
    </div>
  );
}

export default Checkout;
