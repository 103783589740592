export const convertMonetary = (value, replace = true) => {
    const divider = 100;
    
    if (typeof value !== 'number') {
        value = parseFloat(value);
    }

    if (value === 0)
        return 0;

    value = value / divider;
    value = value.toFixed(2);

    if (replace) {
        value = value.replaceAll('.', ',')
    }

    return value;
} 