import axios from "axios";
import ObjectToQueryString from "../../lib/ObjectToQueryString";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

/**
 * Busca informações do pagamento junto ao Polichat
 * @param {*} params
 */
const getCheckoutData = async (params = null) => {
  const url =
    process.env.REACT_APP_LARAVEL_URL +
    `/shopping/checkout/getCheckoutData` +
    (params ? "?" + ObjectToQueryString(params) : "");
  const result = await axios.post(url, {}, defaultConfig);

  return result;
};

/**
 * Submete o pagamento
 * @param {*} params
 */
const submitPayment = (params = null) => {
  if (!params.tkn) {
    throw new Error("Não é possível submeter o pagamento sem o token.");
  }
  return axios.post(
    process.env.REACT_APP_LARAVEL_URL +
      `/shopping/checkout` +
      (params ? "?" + ObjectToQueryString(params) : ""),
    {},
    defaultConfig
  );
};

/**
 * Altera o status do pedido quando o cliente entra na tela
 */
const changeOrderStatus = (params = null) => {
  if (!params.tkn) {
    throw new Error("Não é possível submeter o pagamento sem o token.");
  }

  return axios.post(
    process.env.REACT_APP_LARAVEL_URL +
      `/shopping/checkout/clicked` +
      (params ? "?" + ObjectToQueryString(params) : ""),
    {},
    defaultConfig
  );
};

const submitOrderAddress = (params) => {
  const { orderId, token, address } = params;

  return axios.put(
    process.env.REACT_APP_LARAVEL_URL +
      `/shopping/orders/${orderId}/address?tkn=${token}`,
    address,
    defaultConfig
  );
};

export {
  getCheckoutData,
  submitPayment,
  changeOrderStatus,
  submitOrderAddress,
};
