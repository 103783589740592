import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Row,
  Col,
} from "@polichat/flamboyant";

function ModalStatus({ isOpen, type, title, subtitle, toggle }) {
  const styles = {
    fontSize: "32px",
    fontWeight: "700",
    color: `var(--${type})`,
    display: "inline",
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Icon
              icon={
                type === "success"
                  ? "poli-icon pi-smille-fill"
                  : type === "danger"
                  ? "poli-icon pi-sad-fill"
                  : "poli-icon pi-alert-line"
              }
              size={32}
              color={`var(--${type})`}
            />
          </Col>
        </Row>

        <br />

        <Row>
          <Col>
            <h3 style={styles}>{title}</h3>
          </Col>
        </Row>

        <br />

        <Row>
          <Col>{subtitle}</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalStatus;
