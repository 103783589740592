import React from "react";
import Animation from "../animation";

function Loading() {
  return (
    <Animation
      icon="chat-loading"
      style={{
        width: "100%",
        maxWidth: "500px",
        height: "100%",
        maxHeight: "500px",
      }}
      background="rgba(0, 0, 0, 0)"
      
    />
    
  );
}

export default Loading;
