import { onlyNumbersFilter } from "./onlyNumbersFilter";

export const monthYearMask = (evt) => {
  let value = evt.target.value;
  if (onlyNumbersFilter(evt)) {
    value = value + evt.key;
    value = value.replace("/", "");
    value = value.substr(0, 4);

    if (value.length >= 2) {
      value = value.substr(0, 2) + "/" + value.substr(2, 2);
    }
  } else {
    if (
      evt.keyCode === 13 || // enter
      evt.keyCode === 12 || // tab
      evt.keyCode === 8 || // backspace
      evt.keyCode === 18 || // ctrl
      evt.keyCode === 9 || // delete
      evt.keyCode === 46 || // delete
      /**
       * Arrow keys
       */
      evt.keyCode === 37 ||
      evt.keyCode === 40 ||
      evt.keyCode === 38 ||
      evt.keyCode === 39
    ) {
      return;
    }
  }
  evt.preventDefault();
  evt.target.value = value;
};
