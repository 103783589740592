export const onlyNumbersFilter = (evt) => {
  evt = evt || window.event;
  let keyCode = evt.keyCode;

  if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
    return true;
  }

  if (keyCode > 31 && (keyCode < 47 || keyCode > 57)) {
    evt.preventDefault();
  }
  return false;
};
