import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import { getCheckoutData } from "../../../services/payment/paymentConfig";
import Checkout from "../../../pages/checkout";
import MercadoPagoCheckout from "../../../pages/mercadoPago/mercadoPago";
import Loading from "../loading";


function RenderPage() {
  const [status, setStatus] = useState("");
  const [page, setPage] = useState("")
  const [isloading , setIsLoading]= useState(true)
  const tkn = useParams().id;
  

  const getPageType = useCallback(() => {
   
    const getSuccess = (data) => {
      if (data) {
        //chamamos o loading durante a requisição e quando essa for finalizada
        //o loading é colocado em estado 'falso'
        setIsLoading(true)
        if (data.type === "mercadopago") {
          setPage(data.type) 
        } else if (data.type === "pagseguro") {
            setPage(data.type) 
           
        }
      } else {
        console.error("err");
      }
      setIsLoading(false)
    };

    if (status !== "STATUS_SERVER_OFFLINE") {
      if (tkn) {
        let data = {
          tkn,
        };

        getCheckoutData(data)
          .then(function (result) {
            getSuccess(result.data);
          })
          .catch(function (err) {
            console.error(err);
          });
      } else {
        setStatus("STATUS_SERVER_OFFLINE");
      }
    }
  }, [tkn,status]);
  useEffect(() => {
    getPageType();
  }, [getPageType]);


  
  return(
      <>
      {isloading ?(
        <div className="checkout-loading-container content-grid">
          <Loading />
        </div>
      ) :
       <>{page === 'mercadopago' && page !== ""?   <MercadoPagoCheckout />  : null }
       {page === 'pagseguro' && page !== ""? <Checkout /> : null } </> }
      </>
  )
}

export default RenderPage;