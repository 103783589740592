import axios from "axios";
const baseUrl = "https://viacep.com.br/ws/";

export const getAddress = (cep) => {
  try {
    if (cep.length) {
      cep = cep.replaceAll(" ", "");
      cep = cep.replaceAll("-", "");

      return axios.get(baseUrl + cep + "/json");
    }
  } catch (e) {
    console.error(e);
  }
};
